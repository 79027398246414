import React, { memo, useEffect, useRef, useState } from "react";
import { Card, Input, Radio, Button, message, Space } from "antd";
import gcoord from "gcoord";
import { Mainwrap } from "./style";
const Googlemap = memo((prop) => {
  const [jd, setjd] = useState("");
  const [wd, setwd] = useState("");
  const { fix } = prop;
  const [messageApi, contextHolder] = message.useMessage();

  const inputref = useRef();
  //动态存储历史选中的坐标系

  //配置地图坐标系选择框,获取当前选中的元素
  const [radiovalue, setradioValue] = useState(
    sessionStorage.getItem("groodcheck") === null
      ? 3
      : sessionStorage.getItem("groodcheck")
  );
  //    console.log(radiovalue)

  const onChange = (e) => {
    // console.log("radio checked", e.target.value);
    setradioValue(e.target.value);
    const radiovalue = e.target.value;
    const strvalue = inputref.current.input.value;
    const decimalRegex = /\d+\.\d+/g;
    const match = strvalue.match(decimalRegex);
    if (match.length !== 0) {
      match.sort((a, b) => a - b);
      // console.log(match);
      // setjd(match[1]);
      // setwd(match[0]);
    }
    switch (parseInt(radiovalue)) {
      case 3:
        //什么都不做
        // console.log(1)
        setjd(match[1]);
        setwd(match[0]);
        return 0;

      case 1:
        //执行百度转原始

        const value = gcoord.transform(
          [match[1], match[0]],
          gcoord.BD09,
          gcoord.WGS84
        );
        setjd(value[0]);
        setwd(value[1]);
        // console.log(value);

        return 0;
      case 2:
        //执行火星转原始

        const valuejwd = gcoord.transform(
          [match[1], match[0]],
          gcoord.GCJ02,
          gcoord.WGS84
        );
        setjd(valuejwd[0]);
        setwd(valuejwd[1]);
        // console.log(value);

        return 0;
      default:
        break;
    }
  };

  const jwdchange = () => {
    //    console.log(inputref.current.input.value)
    const strvalue = inputref.current.input.value;
    const decimalRegex = /\d+\.\d+/g;
    const match = strvalue.match(decimalRegex);
    if (match.length !== 0) {
      match.sort((a, b) => a - b);
      console.log(match);
      setjd(match[1]);
      setwd(match[0]);

      switch (parseInt(radiovalue)) {
        case 3:
          //什么都不做
          // console.log(1)
          setjd(match[1]);
          setwd(match[0]);
          return 0;

        case 1:
          //执行百度转原始

          const value = gcoord.transform(
            [match[1], match[0]],
            gcoord.BD09,
            gcoord.WGS84
          );
          setjd(value[0]);
          setwd(value[1]);
          // console.log(value);

          return 0;
        case 2:
          //执行火星转原始

          const valuejwd = gcoord.transform(
            [match[1], match[0]],
            gcoord.GCJ02,
            gcoord.WGS84
          );
          setjd(valuejwd[0]);
          setwd(valuejwd[1]);
          // console.log(value);

          return 0;
        default:
          break;
      }
    }
  };
  const exchange = () => {
    const oldjd = jd;
    const oldwd = wd;
    setjd(oldwd);
    setwd(oldjd);
  };

  //前往谷歌地图
  const gogoogle = () => {
    const baseurl = "https://www.google.com/maps/search/";
    const newurl = baseurl + `${wd},${jd}`;
    window.open(newurl, "_blank");
  };
  return (
    <Mainwrap>
      {contextHolder}

      <div className="inputwrap">
        {/* 上传框 */}

        {/* 批量录入输入框 */}
        <p>
          注：根据坐标系跳转至谷歌地图的功能(访问不了谷歌请自行解决),跳转之后请手动更改地图图层至卫星地图
        </p>
        <Input
          rows={9}
          ref={inputref}
          placeholder="输入坐标并选择坐标系后自动跳转到谷歌地图"
          className="margin-end"
          onChange={jwdchange}
        />

        {/* 选择坐标系的框 */}

        <Radio.Group
          onChange={onChange}
          value={parseInt(radiovalue)}
          size="small"
          defaultValue={3}
          className="margin-end"
        >
          <Radio value={3}>原始</Radio>
          <Radio value={1}>百度</Radio>
          <Radio value={2}>火星(高德、QQ)</Radio>
        </Radio.Group>
        <div className="jwdchange">
          <p>
            纬度：{wd}，经度：{jd}
          </p>

          <Button className="margin-end" type="primary" onClick={exchange}>
            经纬度交换
          </Button>
        </div>
        <Button type="primary" block onClick={gogoogle}>
          去谷歌地图
        </Button>
        {/* {shareurl == "烽哥牛逼" ? null : 
  
              <Card title="扫码导航" className="cardtitle">
              <Card.Grid hoverable={false} className="carderweima">
                  <QRCode value={shareurl} className="carderweima1"/>
                  </Card.Grid>
              </Card>
              } */}
      </div>
    </Mainwrap>
  );
});

export default Googlemap;
