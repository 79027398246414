import React, { memo, useRef, useState } from "react";
import dayjs from "dayjs";
import { Mainwrap } from "./style";
import jingweidumuban from "../../../img/muban.xls"
import jizhanmuban from "../../../img/lacmuban.xls"
import {
  Upload,
  Divider,
  Button,
  Card,
  Input,
  message,
  TimePicker,
  InputNumber,
  Table,
  Tooltip,
  Cascader,
} from "antd";
import http from "../../../services/request";
import locale from "antd/es/date-picker/locale/zh_CN";
import * as XLSX from "xlsx";
import gcoord from "gcoord";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import {
    pengzhuangpointdata,
    changeshowplist_all,
    openshowplist,
} from "../../../store/mappointSlice"

const Pengzhuang = memo((prop) => {
  const BMap = window.BMapGL;
  const { map } = prop;
  //1代表第一个上传框，2代表第二个上传框
  const [value1, setvalue1] = useState(1);
  const [value2, setvalue2] = useState(1);
  const input1ref = useRef();
  const input2ref = useRef();
  const [time, settime] = useState(null);
  const distanceref = useRef();

  const [gps1, setgps1] = useState({ name: "", gcoorddata: [] });
  const [gps2, setgps2] = useState({ name: "", gcoorddata: [] });

  const [pointarr,setpointarr]=useState([])
  const [gpsendall, setgpsendall] = useState([]);
  const [showdadain, setshowdadian] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const dispatch=useDispatch()
  //Table表的表头配置
  const columns = [
    {
      title: "名称",
      dataIndex: "type1",
      key: "type1",
      width: 80,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: "时间",
      dataIndex: "time1",
      key: "time1",
      width: 180,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: "经纬度",
      dataIndex: "latlng1",
      key: "latlng1",
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: "名称",
      dataIndex: "type2",
      key: "type2",
      width: 80,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: "时间",
      dataIndex: "time2",
      key: "time2",
      width: 180,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: "经纬度",
      dataIndex: "latlng2",
      key: "latlng2",
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: "时间差",
      dataIndex: "time",
      key: "time",
      render: (text, record, index) => {
        // console.log("text:", text);
        const miao = text % 60;
        const fen = (text - miao) / 60;
        const time = `${fen}分${miao}秒`;
        return (
          <Tooltip placement="topLeft" title={text}>
            {time}
          </Tooltip>
        );
      },
    },
    {
      title: "距离",
      dataIndex: "distance",
      key: "distance",
      render: (text) => {
        return (
          <Tooltip placement="topLeft" title={text}>
            {`${Math.floor(text)}米`}
          </Tooltip>
        );
      },
    },
  ];
  //联级选择框
  const Cascaderoptions = [
    {
      value: "经纬度",
      label: "经纬度",
      children: [
        {
          value: "1",
          label: "百度",
        },
        {
          value: "2",
          label: "高德",
        },
        {
          value: "3",
          label: "原始",
        },
      ],
    },
    {
      value: "基站",
      label: "基站",
      children: [
        {
          value: "4",
          label: "移动",
        },
        {
          value: "5",
          label: "联通",
        },
      ],
    },
  ];
  const datasouce = gpsendall.map((item, index) => {
    // console.log(item);
    return {
      type1: item.basearr.type,
      time1: item.basearr.bz,
      latlng1: `${item.basearr.lng},${item.basearr.lat}`,
      type2: item.pipeiarr.type,
      time2: item.pipeiarr.bz,
      latlng2: `${item.pipeiarr.lng},${item.pipeiarr.lat}`,
      time: item.time,
      distance: item.distance,
    };
  });

  //坐标系转化的函数
  const gpschange = (radiovalue, inputarr) => {
    // console.log("inputarr:",inputarr)
    // inputarr.forEach((item, index) => {
    //   inputarr[index] = inputarr[index].join(",");
    // });
    let changearr = [];
    let itemarr = [];
    switch (parseInt(radiovalue)) {
      case 1:
        //什么都不做
        // console.log(1)
        // inputarr.forEach((item) => {
        //   changearr.push(item.split(",", 3));
        //   //   console.log(item.split(",", 3));
        // });
        //  console.log(inputarr);
        // console.log(changearr);
        return inputarr;

      case 2:
        //执行火星坐标转百度坐标
        inputarr.forEach((item) => {
          //   itemarr = item.split(",", 3);
          //   console.log(itemarr)
          item.length === 3
            ? changearr.push(
                gcoord
                  .transform(item.slice(0, 2), gcoord.GCJ02, gcoord.BD09)
                  .concat(item[2])
              )
            : changearr.push(
                gcoord.transform(item.slice(0, 2), gcoord.GCJ02, gcoord.BD09)
              );
        });
        return changearr;
      case 3:
        //执行原始坐标转百度坐标
        inputarr.forEach((item) => {
          //   itemarr = item.split(",", 3);
          //   console.log(itemarr)
          item.length === 3
            ? changearr.push(
                gcoord
                  .transform(item.slice(0, 2), gcoord.WGS84, gcoord.BD09)
                  .concat(item[2])
              )
            : changearr.push(
                gcoord.transform(item.slice(0, 2), gcoord.WGS84, gcoord.BD09)
              );
        });
        return changearr;
      // inputarr.forEach(item => {
      //     changearr.push(
      //         gcoord.transform(item.split(","),gcoord.WGS84,gcoord.BD09)
      //     )
      // });
      default:
        break;
    }
  };
  //设置excel传递之后的解析
  const beforeUpload = (file, fileList, setgps, inputref) => {
    // if (refvalue.current.input.value === "") {
    //   messageApi.open({
    //     type: "error",
    //     content: "请先输入轨迹名称之后上传数据",
    //     duration: 3,
    //   });
    //   return;
    // }

    var rABS = true;
    const f = fileList[0];
    //获取当前的文件名
    const name = f.name.split(".")[0];
    inputref.current.input.value = name;

    // console.log(name)
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      if (!rABS) data = new Uint8Array(data);
      var workbook = XLSX.read(data, {
        type: rABS ? "binary" : "array",
      });
      // 假设我们的数据在第一个标签
      var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      // XLSX自带了一个工具把导入的数据转成json
      var jsonArr = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });
      jsonArr.splice(0, 1);
      //此处需要根据底下选中的坐标系对坐标数据进行转化，然后通过redux进行派发
      console.log("jsonArr:", jsonArr);
      //   const changearr = gpschange(value, jsonArr);
      //   console.log("refvalue:", refvalue.current.input.value);
      //   console.log("changearr:", changearr);
      setgps({
        gcoorddata: jsonArr,
      });
      //这里是已经去重的数组
      // let uniqueArr = Array.from(new Set(lacarr.map(JSON.stringify))).map(
      //   JSON.parse
      // );
    };
    if (rABS) reader.readAsBinaryString(f);
    else reader.readAsArrayBuffer(f);
    return false;
  };

  const props = {
    name: "excelfile",
    accept: ".xls , .xlsx",
    maxCount: 1,
    multiple: true,
    //解析上传的excel
    // beforeUpload: (file, fileList) => {
    //   var rABS = true;
    //   const f = fileList[0];
    //   var reader = new FileReader();
    //   reader.onload = function (e) {
    //     var data = e.target.result;
    //     if (!rABS) data = new Uint8Array(data);
    //     var workbook = XLSX.read(data, {
    //       type: rABS ? "binary" : "array",
    //     });
    //     // 假设我们的数据在第一个标签
    //     var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
    //     // XLSX自带了一个工具把导入的数据转成json
    //     var jsonArr = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });
    //     jsonArr.splice(0, 1);
    //     //此处需要根据底下选中的坐标系对坐标数据进行转化，然后通过redux进行派发
    //     console.log("jsonArr:", jsonArr);
    //    const changearr= gpschange(value1,jsonArr)
    //    console.log("changearr:",changearr)
    //     //这里是已经去重的数组
    //     // let uniqueArr = Array.from(new Set(lacarr.map(JSON.stringify))).map(
    //     //   JSON.parse
    //     // );
    //   };
    //   if (rABS) reader.readAsBinaryString(f);
    //   else reader.readAsArrayBuffer(f);
    //   return false;
    // }
  };

  //计算当前选中时间的总秒数
  const timechange = (value) => {
    // settime(value);
    const hours = value.hour();
    const minutes = value.minute();
    const seconds = value.second();
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    // console.log(totalSeconds)
    settime(totalSeconds);
  };

  const jiexi = async (uparr, type) => {
    // console.log("上传需要处理的基站:",uparr)
    let arrlac = []; //存放解析之后经纬度的数组
    let errarr = [];
    for (const item of uparr) {
      await http({
        url: "lbsgps",
        params: {
          mcc: 460,
          mnc: type,
          lac: item.lac[0],
          ci: item.lac[1],
          coord: "bd09",
          output: "json",
        },
      }).then(
        (response) => {
          //    console.log(response)
          if (parseInt(response.errcode) === 0) {
            //查询成功
            arrlac.push({
              coord: [response.lon, response.lat],
              id: item.id,
              radius: response.radius,
            });
          } else {
            errarr.push({
              lac: item.lac[0],
              ci: item.lac[1],
              id: item.id,
            });
          }
        },
        (error) => {
          console.log("错误", error.message);
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 3,
          });
        }
      );
    }
    // console.log(arrlac,errarr)
    const result = {
      success: arrlac,
      error: errarr,
    };
    // console.log("查询成功的基站：",arrlac)
    let endarr = [];
    arrlac.forEach((item) => {
      uparr.forEach((basedata) => {
        if (item.id === basedata.id) {
          endarr.push({
            jizhan: {
              lac: basedata.lac[0],
              cid: basedata.lac[1],
            },
            id: item.id,
            bz: basedata.lac[2],
            lng: item.coord[0],
            lat: item.coord[1],
          });
        }
      });
    });
    return endarr;
  };

  const gpsgaibian = async (value, data) => {
    return new Promise((resolve, reject) => {
      // console.log(data.gcoorddata);
      let arr = [];
      if (value <= 3) {
        // console.log(gpschange(value, data.gcoorddata));
        const gpschangearr = gpschange(value, data.gcoorddata).map(
          (item, index) => {
            return {
              lng: item[0],
              lat: item[1],
              bz: item[2],
            };
          }
        );

        resolve(gpschangearr);
      } else {
        data.gcoorddata.forEach((item) => {
          arr.push({
            id: uuidv4(),
            lac: item,
          });
        });
        jiexi(arr, value - 3).then((res) => {
          //   console.log(res);
          resolve(res);
        });
      }
    });
  };

  let timearr = [];
  const pengzhuang = async () => {
    //对数据进行鉴权
    let dataright = true;
    if (gps1.gcoorddata.length === 0 || gps2.gcoorddata.length === 0) {
      messageApi.open({
        type: "error",
        content: "文件一或文件二未上传数据",
        duration: 3,
      });
      dataright = false;
      return;
    }

    if (
      input1ref.current.input.value === "" ||
      input2ref.current.input.value === ""
    ) {
      messageApi.open({
        type: "error",
        content: "文件一或文件二未命名",
        duration: 3,
      });
      dataright = false;
      return;
    }

    if (time === null || time === "") {
      messageApi.open({
        type: "error",
        content: "时间未选择",
        duration: 3,
      });
      dataright = false;
      return;
    }

    if (distanceref.current.value === "") {
      messageApi.open({
        type: "error",
        content: "距离未选择",
        duration: 3,
      });
      dataright = false;
      return;
    }
    if (dataright) {
      messageApi.open({
        type: "success",
        content: "数据正在计算中，请稍等",
        duration: 3,
      });
    }
    // console.log(gps1);
    // console.log(gps2);
    // console.log(input1ref.current.input.value);
    // console.log(input2ref.current.input.value);
    // console.log(distanceref.current.value);
    // console.log(time);

    //先对坐标系进行转化

    // const gpschange1 = await gpsgaibian(value1, gps1);
    // const gpschange2 = await gpsgaibian(value2, gps2);

    let objgps = [];

    // console.log("gpschange1:", gpschange1);
    // console.log("gpschange2:", gpschange2);
    //将每一条数据打上对应的数据标签
    let allarr;






    {/*  核心的计算代码是这一段，其中res里面包含经度纬度，bz是时间字段        */}
    Promise.all([gpsgaibian(value1, gps1), gpsgaibian(value2, gps2)]).then(
      (res) => {
        // console.log(res[0])
        res[0].forEach((item, index) => {
          objgps.push({
            id: uuidv4(),
            lng: item.lng,
            lat: item.lat,
            bz: item.bz,
            type: input1ref.current.input.value,
            jizhan: item?.jizhan,
          });
        });
        res[1].forEach((item, index) => {
          objgps.push({
            id: uuidv4(),
            lng: item.lng,
            lat: item.lat,
            bz: item.bz,
            type: input2ref.current.input.value,
            jizhan: item?.jizhan,
          });
        });
        //   console.log("objgps:",objgps)

        allarr = objgps;
        allarr.forEach((item, index) => {
          if (typeof item.bz === "number") {
            //excel时间为文本，开始转化
            // 计算时间部分
            const days = Math.floor(item.bz);
            const milliseconds = Math.round((item.bz % 1) * 86400 * 1000);
            const date = new Date(
              Date.UTC(1899, 11, 30 + days, 0, 0, 0, milliseconds)
            );

            // 提取年月日时分秒
            const year = date.getUTCFullYear();
            const month = date.getUTCMonth() + 1; // 月份从 0 开始，所以需要加 1
            const day = date.getUTCDate();
            const hours = date.getUTCHours();
            const minutes = date.getUTCMinutes();
            const seconds = date.getUTCSeconds();

            // 构建时间字符串
            const dateString = `${year}-${month
              .toString()
              .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
            const timeString = `${hours.toString().padStart(2, "0")}:${minutes
              .toString()
              .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
            const fullTimeString = `${dateString} ${timeString}`;

            // console.log(fullTimeString);
            item.bz = fullTimeString;
          }
        });
        //对数据进行排序，sort会根据返回值来调整数组中数据的位置
        allarr.sort((a, b) => {
          const timeA = dayjs(a.bz); // 假设对象中的时间元素名为 time
          const timeB = dayjs(b.bz);

          if (timeA > timeB) {
            return -1; // 返回负数表示 a 在 b 前面
          } else if (timeA < timeB) {
            return 1; // 返回正数表示 a 在 b 后面
          } else {
            return 0; // 返回 0 表示两者相等
          }
        });
        //   console.log("allarr:",allarr);

        //对时间进行排序
        let addpointarr=[]
        allarr.forEach((item, index) => {
          let i = index + 1;
          for (i; i < allarr.length; i++) {
            if (i <= allarr.length - 1) {
              // console.log("item:",item)
              // console.log("allarr[index]:",allarr[i])
              //获取当前时间的差值
              const diff = dayjs(item.bz).diff(dayjs(allarr[i].bz));
              const diffSeconds = Math.floor(diff / 1000);
              //获取当前的数据类型
              const type = allarr[i].type;

              const point1 = new BMap.Point(item.lng, item.lat);
              const point2 = new BMap.Point(allarr[i].lng, allarr[i].lat);
              const juli = map.getDistance(point1, point2);

              //   console.log("当前的时间差：", diffSeconds);
              //     console.log("当前需要对比的时间差：", time);
              //     console.log("当前的种类：", item.type);
              //     console.log("需要对比的种类：", type);
              //    console.log("当前两个点的距离为:", juli);
              //    console.log("需要对比的距离：",distanceref.current.value );
              if (time < diffSeconds) {
                break;
              } else if (type === item.type) {
                continue;
              } else if (juli < distanceref.current.value) {
                timearr.push({
                  basearr: item,
                  pipeiarr: allarr[i],
                  distance: juli,
                  time: diffSeconds,
                });
                //添加数据到将要使用打点的数组中
                addpointarr.push([item.lng,item.lat,`${item.bz} ${item.type}`])
                addpointarr.push([allarr[i].lng,allarr[i].lat,`${allarr[i].bz} ${allarr[i].type}`])
                // console.log("距离：", juli);
                // console.log("时间：", diffSeconds);
                // console.log("数据一：", item);
                // console.log("数据二：", allarr[i]);
              }
            }
          }
        });
        console.log(addpointarr)
        const uniqueArr = [...new Set(addpointarr)];
        console.log(uniqueArr)
        setpointarr(uniqueArr)
        setgpsendall(timearr);
        //   console.log(allarr)
        // console.log(timearr)
        if (timearr.length !== 0) {
          setshowdadian(true);
          messageApi.open({
            type: "success",
            content: "已经检索完成",
            duration: 3,
          });
        } else {
          setshowdadian(false);
          messageApi.open({
            type: "error",
            content: "已经检索完成，未发现符合条件的数据",
            duration: 3,
          });
        }
      }
    );
     {/*  核心的计算代码是这一段，其中res里面包含经度纬度，bz是时间字段        */}
  };
  //批量打点
  const dadian = () => {
    // messageApi.open({
    //   type: "success",
    //   content: "打点！",
    //   duration: 3,
    // });
    dispatch(pengzhuangpointdata({mappointlist:pointarr}))
        //打开点位列表
    dispatch(changeshowplist_all());
    //调用父组件中的函数关闭modal
    dispatch(openshowplist());
    // console.log("pointarr:", pointarr);
  };

  const Cascaderchange1 = (value) => {
    setvalue1(parseInt(value[1]));
    // console.log(parseInt(value[1]))
  };
  const Cascaderchange2 = (value) => {
    setvalue2(parseInt(value[1]));
    // console.log(parseInt(value[1]))
  };
  return (
    <Mainwrap>
      {contextHolder}
      <div className="left">
        <Upload
          style={{ width: 200, heiget: 200 }}
          {...props}
          beforeUpload={(file, fileList) =>
            beforeUpload(file, fileList, setgps1, input1ref)
          }
        >
          文件： <Button>上传数据一</Button>
        </Upload>
        名称：
        <Input
          placeholder="输入轨迹名称，用于数据碰撞之后区分"
          ref={input1ref}
          style={{ width: 280 }}
        />
        <div>
          类型：
          <Cascader
            options={Cascaderoptions}
            defaultValue={["经纬度", "1"]}
            onChange={Cascaderchange1}
          />
        </div>
        {/* <Radio.Group
          onChange={onradio1Change}
          value={parseInt(value1)}
          size="small"
        >
          <Radio defaultChecked value={1}>
            百度
          </Radio>
          <Radio value={2}>火星(高德、QQ)</Radio>
          <Radio value={3}>原始</Radio>
        </Radio.Group> */}
        <p>数据量：{gps1.gcoorddata.length}</p>
        <Divider />
        <Upload
          style={{ width: 200, heiget: 200 }}
          {...props}
          beforeUpload={(file, fileList) =>
            beforeUpload(file, fileList, setgps2, input2ref)
          }
        >
          文件： <Button>上传数据二</Button>
        </Upload>
        名称：
        <Input
          placeholder="输入轨迹名称，用于数据碰撞之后区分"
          ref={input2ref}
          style={{ width: 280 }}
        />
        <div>
          类型：
          <Cascader
            options={Cascaderoptions}
            defaultValue={["经纬度", "1"]}
            onChange={Cascaderchange2}
          />
        </div>
        {/* <Radio.Group
          onChange={onradio2Change}
          value={parseInt(value2)}
          size="small"
        >
          <Radio defaultChecked value={1}>
            百度
          </Radio>
          <Radio value={2}>火星(高德、QQ)</Radio>
          <Radio value={3}>原始</Radio>
        </Radio.Group> */}
        <p>数据量：{gps2.gcoorddata.length}</p>
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p>比对时间(时分秒)</p>
          <TimePicker
            onChange={(value) => timechange(value)}
            locale={locale}
            style={{ width: 150 }}
          />
          <p>比对距离(单位：米)</p>
          <InputNumber ref={distanceref} defaultValue={200} />
        </div>
        <Divider />
        <div className="centerbtn">
          <Button type="primary" onClick={pengzhuang}>
            开始碰撞
          </Button>
        </div>
      </div>

      <Divider type="vertical" />

      <div className="right">
        {showdadain ? (
          <Button type="primary" onClick={dadian}>
            批量打点
          </Button>
        ) : null}

        <Table
          columns={columns}
          dataSource={datasouce}
          pagination={{
            pageSize: 11,
            showTotal: (total) => `共计 ${total} 条`,
          }}
        />
        {showdadain ? null : (
          <Card title="使用说明" style={{ marginTop: 30 }}>
            <h2>功能：</h2>
            <h3>
              根据给定的时间长度和距离去计算两份数据中符合条件的数据，支持经纬度类型数据和基站数据比对（基站数据会自动转化为经纬度数据）
            </h3>
            <h2>注意：</h2>
            <h3>
              1.在上传数据的时候必须提供时间字段，并请保证上传的数据是连续完整的（excel表中间不能有空行）
            </h3>
            <h3>2.名称作为区分两份数据的唯一标识，必须输入</h3>
            <h3>
              3.开始匹配之后数据按照时间从近到远进行排序之后匹配，匹配顺序为往下匹配。
            </h3>
            <h3>
              4.上传数据第三列必须是时间格式的数据，用于计算时间差。
            </h3>
            <a href={jingweidumuban}>经纬度上传模板</a>
            <br/>
            <a href={jizhanmuban}>基站上传模板</a>
          </Card>
        )}
      </div>
    </Mainwrap>
  );
});

export default Pengzhuang;
