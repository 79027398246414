import React, { memo, useState, useRef } from "react";
import {
  CommentOutlined,
  MinusOutlined,
  ExpandAltOutlined,
  EnvironmentOutlined,
  PlusOutlined,
  GlobalOutlined,
  WifiOutlined,
  StarOutlined,
  UsergroupDeleteOutlined,
  CloudUploadOutlined,
  BankOutlined,
  GoogleOutlined,
} from "@ant-design/icons";
import Draggable from "react-draggable";

import lacicon from "../../img/lac.png";
import drawicon from "../../img/draw.png";
import circleicon from "../../img/circle.png";
import polygonicon from "../../img/polygon.png";
import IPicon from "../../img/ip.png";
import { Mainwrap } from "./style";
import { Button, FloatButton, Modal, Drawer, Space, message } from "antd";
import Gpscon from "./c-gpschange";
import Pointadd from "./c-pointadd/c-input";
import { updataonpoint, addonepointaction } from "../../store/mappointSlice";
import { useDispatch, useSelector } from "react-redux";
import { addonepointf } from "./addone/addone";
import Morelacany from "./morelacany";
import LBS from "./lbs";
import Wifi from "./wifi";
import Save from "./save";
import CloudSave from "./cloudsave";
import Geohash from "../headerfile/geohash";
import Googlemap from "./googlemap";
import { linedraw } from "./mapdraw/polyline";
import { circledraw } from "./mapdraw/circle";
import { polygondraw } from "./mapdraw/polygon";
import Pengzhuang from "./pengzhuang";
import IPsearch from "./ip";

const Btnapp = memo((props) => {
  const BMap = window.BMapGL;
  const { map, mapdrawclick, zoomControl } = props;

  //获取当前的用户登录情况
  const islogin = useSelector((store) => store.user.islogin);
  const [messageApi, contextHolder] = message.useMessage();
  //折线图的数据
  const { clicknum } = useSelector((store) => store.circle);

  //拖拽相关
  const draggleRef = useRef(null);
  const [disabled, setDisabled] = useState(false);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };
  // -------------------------------------

  const [gpsModalOpen, setgpsModalOpen] = useState(false);
  const [addone, setaddone] = useState("auto");
  //关闭gpsmodal
  const gpshandleCancel = () => {
    setgpsModalOpen(false);
  };
  //打开gpsmodal
  const opengpschange = () => {
    setgpsModalOpen(true);
  };

  //控制打点控件显示
  const [pointModalOpen, setpointModalOpen] = useState(false);
  const pointhandleCancel = () => {
    setpointModalOpen(false);
  };
  //打开gpsmodal
  const openpointchange = () => {
    setpointModalOpen(true);
  };

  //控制点位显示控件
  const dispatch = useDispatch();
  //打开gpsmodal

  const addonepoint = () => {
    setaddone("none");
    //处理点位点击添加的函数
    addonepointf(map, dispatch, setaddone, BMap);
  };

  //设置置顶
  const [fix, setfix] = useState(false);
  const fixmo = () => {
    setfix(!fix);
  };

  //切换地图样式
  const [maptype, setmaptype] = useState(false);
  const changemap = () => {
    setmaptype(!maptype);
    maptype ? map.setMapType("B_NORMAL_MAP") : map.setMapType("B_EARTH_MAP");
  };

  //基站解析
  const [lbsModalOpen, setlbsModalOpen] = useState(false);
  const lbshandleCancel = () => {
    setlbsModalOpen(false);
  };
  //打开gpsmodal
  const lbssearch = () => {
    setlbsModalOpen(true);
  };

  //wifi解析
  const [wifiModalOpen, setwifiModalOpen] = useState(false);
  const wifihandleCancel = () => {
    setwifiModalOpen(false);
  };
  const wifisearch = () => {
    setwifiModalOpen(true);
  };

  //打开地图编辑选项
  const [isshow, setisshow] = useState(false);
  const showdraw = () => {
    setisshow(!isshow);
  };

  //开启画线

  const lineclick = () => {
    linedraw(map, dispatch, setaddone);
    //让画线按钮变为不可用
    setaddone("none");
  };

  //开启画圆
  const circleclick = () => {
    circledraw(map, dispatch, setaddone, clicknum);
    //让画圆按钮变为不可用
    setaddone("none");
  };

  //开启画多边形
  const polygonclick = () => {
    polygondraw(map, dispatch, setaddone);
    setaddone("none");
  };

  //开启存储界面
  const [issaveshow, setissaveshow] = useState(false);
  const showsave = () => {
    setissaveshow(true);
  };
  const delsaveshow = () => {
    setissaveshow(false);
  };

  //多基站解析
  const [morelacModalOpen, setmorelacModalOpen] = useState(false);
  const morelacCancel = () => {
    setmorelacModalOpen(false);
  };
  const morelacsearch = () => {
    setmorelacModalOpen(true);
  };

  //显示碰撞界面
  const [penzModalOpen, setpenzModalOpen] = useState(false);
  const pengzCancel = () => {
    setpenzModalOpen(false);
  };
  const showpengz = () => {
    setpenzModalOpen(true);
  };

  //geohash转化
  const [geohashmodalshow, setshowhash] = useState(false);
  const opengeohashchange = () => {
    setshowhash(true);
  };
  const setgeohashshowFalse = () => {
    setshowhash(false);
  };

  //云收藏功能
  const [savetype, setsavetype] = useState(false);
  const changesavetype = () => {
    // console.log("islogin:",islogin)
    if (islogin) {
      setsavetype(!savetype);
    } else {
      setsavetype(false);
      messageApi.info("登录后才能使用云收藏功能");
    }
  };

  //跳转至谷歌地图功能
  const [googlemodleopen, setgooglemodleopen] = useState(false);
  const opengooglemodle = () => {
    setgooglemodleopen(true);
  };
  const closrgooglemodle = () => {
    setgooglemodleopen(false);
  };

  //打开IP批量查询的界面
  const [ipmodleopen, setipmodleopen] = useState(false);
  const ipsearchopen = () => {
    setipmodleopen(true);
  };
  const closeipmodle=()=>{
    setipmodleopen(false);
  }
  return (
    <Mainwrap canclick={addone}>
      {contextHolder}
      <FloatButton.Group
        shape="square"
        style={{
          top: 80,
          right: 5,
          height: "20vh",
        }}
      >
        <FloatButton
          tooltip="地图打点"
          onClick={openpointchange}
          icon={<EnvironmentOutlined />}
          description="打点"
        />

        <FloatButton
          tooltip="GEOHASH查询"
          onClick={opengeohashchange}
          icon={<GlobalOutlined />}
          description="GH"
        />
        <FloatButton
          tooltip="经纬度转换"
          onClick={opengpschange}
          icon={<CommentOutlined />}
          description="转换"
        />
        <FloatButton
          tooltip="标注单个点位"
          onClick={addonepoint}
          icon={<PlusOutlined />}
          description="取点"
          className="addonepoint"
        />
        <FloatButton
          tooltip="切换地图样式"
          onClick={changemap}
          icon={<ExpandAltOutlined />}
          description={maptype ? "电子" : "卫星"}
          className="addonepoint"
        />
        {/* <FloatButton
          tooltip="基站解析"
          onClick={lbssearch}
          icon={<img src={lacicon} height={18} />}
          description="基站"
          className="addonepoint"
        /> */}
        <FloatButton
          tooltip="多基站解析"
          onClick={morelacsearch}
          icon={<img src={lacicon} height={18} />}
          description="基站"
          className="addonepoint"
        />
        <FloatButton
          tooltip="IP批量查询"
          onClick={ipsearchopen}
          icon={<img src={IPicon} height={18} />}
          description="IP"
          className="addonepoint"
        />

        <FloatButton
          tooltip="WIFI查询"
          onClick={wifisearch}
          icon={<WifiOutlined />}
          description="WIFI"
          className="addonepoint"
        />

        <FloatButton
          tooltip="绘制线（测距）圆多边形"
          onClick={showdraw}
          icon={<img src={drawicon} height={18} />}
          description="绘图"
          className="addonepoint"
        />

        <FloatButton
          tooltip="轨迹碰撞"
          onClick={showpengz}
          icon={<UsergroupDeleteOutlined />}
          description="碰撞"
          className="addonepoint"
        />

        <FloatButton
          tooltip="收藏内容"
          onClick={showsave}
          icon={<StarOutlined />}
          description="收藏"
          className="addonepoint"
        />

        <FloatButton
          tooltip="快速前往谷歌地图"
          onClick={opengooglemodle}
          icon={<GoogleOutlined />}
          description="谷歌"
          className="addonepoint"
        />
      </FloatButton.Group>

      {/* 地图打点展开的二级按钮 */}
      {isshow && (
        <FloatButton.Group
          shape="square"
          style={{
            top: 325,
            right: 48,
            height: "10vh",
          }}
        >
          <FloatButton
            tooltip="单击开始画线，右击结束，双击删除"
            icon={<MinusOutlined />}
            description="线"
            className="polyline"
            onClick={lineclick}
          />
          <FloatButton
            icon={<img src={circleicon} height={18} />}
            description="圆"
            className="circle"
            onClick={circleclick}
          />
          <FloatButton
            tooltip="单击开始画多边形，右击结束，双击删除"
            icon={<img src={polygonicon} height={18} />}
            description="多边"
            className="polygon"
            onClick={polygonclick}
          />
        </FloatButton.Group>
      )}
      <Modal
        title="IP批量查询"
        open={ipmodleopen}
        onCancel={closeipmodle}
        destroyOnClose={true}
        footer={[]}
        width={850}
      >
        <IPsearch />
      </Modal>
      {/* ---------------跳转到谷歌地图的modle---------------- */}
      <Modal
        title="查看谷歌卫星地图"
        open={googlemodleopen}
        onCancel={closrgooglemodle}
        destroyOnClose={true}
        footer={[]}
      >
        <Googlemap />
      </Modal>
      {/* ---------------经纬度转换的modal---------------- */}
      <Modal
        title="经纬度转换"
        open={gpsModalOpen}
        onCancel={gpshandleCancel}
        destroyOnClose={true}
        footer={[]}
      >
        <Gpscon />
      </Modal>

      {/* ---------------基站解析的modal---------------- */}
      <Modal
        title="基站解析"
        open={lbsModalOpen}
        onCancel={lbshandleCancel}
        destroyOnClose={true}
        footer={[]}
      >
        <LBS setlbsModalOpen={setlbsModalOpen} />
      </Modal>
      {/* ---------------wifi解析的modal---------------- */}
      <Modal
        title="WIFI查询"
        open={wifiModalOpen}
        onCancel={wifihandleCancel}
        destroyOnClose={true}
        footer={[]}
      >
        <Wifi setlbsModalOpen={setwifiModalOpen} />
      </Modal>

      <Modal
        title="轨迹碰撞"
        open={penzModalOpen}
        onCancel={pengzCancel}
        destroyOnClose={false}
        footer={[]}
        bodyStyle={{
          width: 1400,
          height: 750,
        }}
        width={1500}
      >
        <Pengzhuang setpenzModalOpen={setpenzModalOpen} map={map} />
      </Modal>

      {/* ---------------地图打点的modal---------------- */}
      <Modal
        title={
          <div>
            <div
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
            >
              地图打点
              {/* <Button type="primary" onClick={fixmo}>
                {fix ? "取消固定" : "固定"}
              </Button> */}
            </div>
          </div>
        }
        open={pointModalOpen}
        onCancel={pointhandleCancel}
        footer={[]}
        destroyOnClose={true}
        //拖拽相关--start
        // modalRender={(modal) => (
        //   <Draggable
        //     disabled={disabled}
        //     bounds={bounds}
        //     onStart={(event, uiData) => onStart(event, uiData)}
        //   >
        //     <div ref={draggleRef}>{modal}</div>
        //   </Draggable>
        // )}
        //   拖拽相关--end
      >
        {/* 把关闭函数作为参数传入 */}
        <Pointadd cancelpoint={pointhandleCancel} fix={fix} />
      </Modal>
      {/* ---------------GH转化的modal---------------- */}
      <Modal
        title="geohash转化"
        open={geohashmodalshow}
        onCancel={setgeohashshowFalse}
        width={500}
        footer={[]}
      >
        <Geohash setgeohashshowFalse={setgeohashshowFalse} />
      </Modal>

      {/*-------------------批量基站解析modal-------------------*/}
      <Modal
        title={
          <div>
            <div
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
            >
              基站解析
              {/* <Button type="primary" onClick={fixmo}>
                {fix ? "取消固定" : "固定"}
              </Button> */}
            </div>
          </div>
        }
        open={morelacModalOpen}
        onCancel={morelacCancel}
        footer={[]}
        //拖拽相关--start
        // modalRender={(modal) => (
        //   <Draggable
        //     disabled={disabled}
        //     bounds={bounds}
        //     onStart={(event, uiData) => onStart(event, uiData)}
        //   >
        //     <div ref={draggleRef}>{modal}</div>
        //   </Draggable>
        // )}
        //   拖拽相关--end
      >
        {/* 把关闭函数作为参数传入 */}
        <Morelacany morelacCancel={morelacCancel} fix={fix} />
      </Modal>

      {/*--------------------保存数据的modal----------------*/}
      <Drawer
        title={savetype ? "云收藏" : "本地收藏"}
        open={issaveshow}
        onClose={delsaveshow}
        width={480}
        // destroyOnClose={true}
        footer={[]}
        destroyOnClose={true}
        extra={
          <Space>
            {savetype ? <BankOutlined /> : <CloudUploadOutlined />}
            <Button onClick={changesavetype}>
              切换为{savetype ? "本地收藏" : "云收藏"}
            </Button>
          </Space>
        }
      >
        {savetype ? <CloudSave /> : <Save />}
      </Drawer>
      {/* <Mapdraw map={map}/> */}

      {/* <Drawer
        title="云收藏"
        open={cloudsave}
        onClose={closecloudsave}
        width={410}
        // destroyOnClose={true}
        footer={[]}
      >
        <CloudSave map={map}/>
      </Drawer> */}
    </Mainwrap>
  );
});

export default Btnapp;
