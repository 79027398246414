import Userface from "../pages/userface";
import Project from "../pages/project/project";
import Projectconfig from "../pages/project/projectconfig"
import Main from '../pages/project/main'
import Usergetaddbyuuid from "../pages/usergetaddbyuuid";

const routes=[
    {
        path:"/",
        //使用Navigate进行重定向
        element:<Userface/>    
    },
    {
        path:"/1",
        element:<Main/>,
    },
    {
        path:"/project",
        element:<Project/>,
    },
    {
        path:"/projectconfig",
        element:<Projectconfig/>
    },
    {
        path:"/usergetaddbyuuid",
        element:<Usergetaddbyuuid/>
    },
    
];
export default routes