import React, { memo, useRef, useState, useEffect } from "react";
import { useBoolean, useToggle } from "ahooks";

import { useSelector, useDispatch } from "react-redux";

import shouce from "../../../src/img/shuom.pdf";
import { Mainwrap } from "./style";
import { Button, Layout, Modal, Menu, Avatar,Image } from "antd";
import http from "../../services/request";
import Imei from "./imei";
import moment from "moment/dist/moment";
import "moment/dist/locale/zh-cn";
import "moment-lunar";
import Monitortime from "./monitortime";
import quncode from "../../img/1722473641451.png"
import Guishu from "./guishu";
import Qrcode from "./qrcode";
import Userlogin from "./userlogin";
import UserQRlogin from "./userQRlogin";
import { loginout } from "../../store/userSlice";

const items = [
  {
    label: "工具集",
    key: "SubMenu",
    children: [
      {
        key: "fun1",
        label: "IMEI补全",
      },
      {
        key: "fun3",
        label: "二维码生成",
      },
      {
        key: "fun2",
        label: "监控时间校准",
      },
      {
        key: "fun4",
        label: "号码归属地查询",
      },
    ],
  },
];

const Headerfile = memo(() => {

  const dispatch=useDispatch()

  const { islogin, user } = useSelector((store) => store.user);
  //IMEI
  const [
    imeimodalshow,
    {
      toggle: toggleimei,
      setTrue: setimeishowTrue,
      setFalse: setimeishowFalse,
    },
  ] = useBoolean(false);
  //监控时间转换
  const [
    monitormodalshow,
    {
      toggle: togglemonitor,
      setTrue: setmonitorshowTrue,
      setFalse: setmonitorshowFalse,
    },
  ] = useBoolean(false);
  //二维码转化
  const [
    qrcodemodalshow,
    {
      toggle: toggleqrcode,
      setTrue: setqrcodeshowTrue,
      setFalse: setqrcodeshowFalse,
    },
  ] = useBoolean(false);

  //用户登录
  const [
    loginmodalshow,
    {
      toggle: togglelogin,
      setTrue: setloginshowTrue,
      setFalse: setloginshowFalse,
    },
  ] = useBoolean(false);

  const { Header } = Layout;
  moment.locale("zh-cn");
  const [currentTime, setCurrentTime] = useState(moment().format("lll"));

  //号码状态查询
  const [
    guishumodalshow,
    {
      toggle: toggleguishu,
      setTrue: setguishushowTrue,
      setFalse: setguishushowFalse,
    },
  ] = useBoolean(false);

  //用户退出
  const [
    outmodalshow,
    {
      toggle: toggleout,
      setTrue: setoutshowTrue,
      setFalse: setoutshowFalse,
    },
  ] = useBoolean(false);


  
  useEffect(() => {
    const interval = setInterval(() => {
      const gettime = async () => {
        const nowtime = await http({
          url: "bjtime",
        }).then((response) => {
          const date = moment(parseInt(response.data.t));

          const formattedDate = date.format("lll");
          // console.log(formattedDate)
          setCurrentTime(formattedDate);
        });
      };

      gettime();
    }, 10000);

    return () => clearInterval(interval);
  }, []);
  const menuclick = (e) => {
    if (e.key === "fun1") {
      setimeishowTrue();
    }
    if (e.key === "fun2") {
      setmonitorshowTrue();
    }
    if (e.key === "fun3") {
      setqrcodeshowTrue();
    }
    if (e.key === "fun4") {
      setguishushowTrue();
    }
  };


  const tuichu=()=>{
    // console.log("退出")
    setoutshowFalse()
    dispatch(loginout())
  }
  //打开钉钉群
  const [qunopen,setqunopen]=useState(false)
  const openquncode=()=>{
    setqunopen(true)
  }
  const closequn=()=>{
    setqunopen(false)
  }
  return (
    <Mainwrap>
      <Layout>
        <Header className="header">
          {/* <div className="logo" >
          </div> */}
          <div className="title"></div>

          <div className="div1">
            <Button className="shouce" onClick={openquncode}>加入钉钉群</Button>
            <div className="funcset">
              <Menu
                mode="horizontal"
                items={items}
                onClick={(e) => menuclick(e)}
              >
                工具集
              </Menu>
            </div>
            <Button
              className="shouce"
              // onClick={showshouce}
            >
              <a href={shouce} target="_blank">
                使用手册
              </a>
            </Button>

            <div className="time">{currentTime}</div>
            {islogin ? (
              <Avatar
                size={40}
                className="avatar"
                onClick={setoutshowTrue}
              >
                {user.name}
              </Avatar>
            ) : (
              <Button
                className="login-btn"
                type="primary"
                onClick={setloginshowTrue}
              >
                {"登录"}
              </Button>
            )}
          </div>
        </Header>
      </Layout>
      <Modal
        title="扫码加入钉钉群"
        open={qunopen}
        onCancel={closequn}
        footer={[]}
        destroyOnClose
      >
        <Image src={quncode}/>
      </Modal>
      <Modal
        title="IMEI补全"
        open={imeimodalshow}
        onCancel={setimeishowFalse}
        footer={[]}
        destroyOnClose
      >
        <Imei />
      </Modal>

      <Modal
        title="监控时间校准"
        open={monitormodalshow}
        onCancel={setmonitorshowFalse}
        width={750}
        footer={[]}
      >
        <Monitortime />
      </Modal>

      <Modal
        title="二维码转化"
        open={qrcodemodalshow}
        onCancel={setqrcodeshowFalse}
        width={520}
        footer={[]}
        destroyOnClose={true}
      >
        <Qrcode setqrcodeshowFalse={setqrcodeshowFalse} />
      </Modal>

      <Modal
        title="归属地查询"
        open={guishumodalshow}
        onCancel={setguishushowFalse}
        footer={[]}
        width={400}
        destroyOnClose={true}
      >
        <Guishu setguishushowFalse={setguishushowFalse} />
      </Modal>

      <Modal
        title="用户登录"
        open={loginmodalshow}
        onCancel={setloginshowFalse}
        footer={[]}
        width={800}
        className="login"
        destroyOnClose={true}
      >
        <UserQRlogin setloginshowFalse={setloginshowFalse} />
      </Modal>




      <Modal 
      title="确定退出吗？"
      open={outmodalshow}
      onCancel={setoutshowFalse}
      onOk={tuichu}
      cancelText="取消"
      ></Modal>
    </Mainwrap>
  );
});

export default Headerfile;
