import styled from "styled-components";

export const Mainwrap = styled.div`
  .tb {
    position: absolute;
    margin-left: 1400px;
    margin-top: 8px;
  }

  .edit {
    position: absolute;
    margin-left: 1300px;
    margin-top: 8px;
  }
  .biaoti {
    position: absolute;
    background-color: #001529;
    width: 300px;
    height: 5vh;
    font-weight: bold;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size:30px
  }
  .casename{
    position: absolute;
    margin-left: 950px;

    width: 180px;
    font-weight: bold;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
`;
