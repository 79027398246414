import FingerprintJS from '@fingerprintjs/fingerprintjs';

const generateFingerprint = async () => {
  return new Promise(async (resolve, reject) => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const fingerprint = result.visitorId;
    // console.log(fingerprint); // 打印浏览器指纹
    resolve(fingerprint);
  });
};

export default generateFingerprint;