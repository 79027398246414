import React, { memo, useRef } from "react";
import { Mainwrap } from "./style";
import { Button, DatePicker, Divider, message } from "antd";
import { useSetState } from "ahooks";
import "dayjs/locale/zh-cn";
import locale from "antd/es/date-picker/locale/zh_CN";
import dayjs from "dayjs";

const Monitortime = memo(() => {
  const [messageApi, contextHolder] = message.useMessage();
  const [state, setState] = useSetState({
    time1: "",
    time2: "",
    poor: "",
    time3: "",
    time4: "",
    time5: "",
    time6: "",
  });

  const time1 = useRef(null);
  const time2 = useRef(null);

  const timechange1 = (value, datastring) => {
    setState({ time1: datastring });
  };

  const timechange2 = (value, datastring) => {
    setState({ time2: datastring });
  };
  const timechange3 = (value, datastring) => {
    setState({ time3: datastring });
  };

  const timechange4 = (value, datastring) => {
    setState({ time5: datastring });
  };
  //计算两个监控的时间差值
  const cal = () => {
    // console.log(state.time1, state.time2);
    const poor = dayjs(state.time2).diff(dayjs(state.time1), "second");
    setState({ poor: poor });
    // console.log(poor);
  };
  //校准时间
  const resettime = () => {
    if (state.poor !== "") {
      const newtime = dayjs(state.time3).add(state.poor, "second");
      console.log(newtime.format("YYYY-MM-DD HH:mm:ss"));
      setState({
        time4: newtime,
      });
      success();
    } else {
      warning();
    }
  };
  const resettime2 = () => {
    if (state.poor !== "") {
      const newtime = dayjs(state.time5).subtract(state.poor, "second");
      //   console.log(newtime.format("YYYY-MM-DD HH:mm:ss"));
      setState({
        time6: newtime,
      });
      success();
    } else {
      warning();
    }
  };

  const success = () => {
    messageApi.open({
      type: "success",
      content: "校准成功！",
    });
  };
  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "请先计算相差时间",
    });
  };
  return (
    <Mainwrap>
      {contextHolder}
      监控一：
      <DatePicker
        showTime
        locale={locale}
        placeholder={"监控一时间"}
        className="time1"
        ref={time1}
        onChange={timechange1}
      />
      <Button type="primary" className="btn" onClick={cal}>
        计算差时
      </Button>
      监控二：
      <DatePicker
        showTime
        locale={locale}
        placeholder={"监控二时间"}
        className="time2"
        ref={time2}
        onChange={timechange2}
      />
      <div className="xiangcha">
        <p>相差时间:{state.poor + "秒"}</p>
      </div>
      <Divider />
      监控一转二：
      <DatePicker
        showTime
        locale={locale}
        placeholder={"待校准的监控一时间"}
        className="time1"
        onChange={timechange3}
      />
      <Button type="primary" className="btn" onClick={resettime}>
        校准
      </Button>
      校准后：
      <DatePicker
        disabled
        showTime
        locale={locale}
        placeholder={"校准后的时间"}
        className="time1"
        value={state.time4}
      />
      <Divider />
      监控二转一：
      <DatePicker
        showTime
        locale={locale}
        placeholder={"待校准的监控二时间"}
        className="time1"
        onChange={timechange4}
      />
      <Button type="primary" className="btn" onClick={resettime2}>
        校准
      </Button>
      校准后：
      <DatePicker
        disabled
        showTime
        locale={locale}
        placeholder={"校准后的时间"}
        className="time1"
        value={state.time6}
      />
    </Mainwrap>
  );
});

export default Monitortime;
