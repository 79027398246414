import styled from "styled-components";

export const Mainwrap = styled.div`
  .tishi {
    font-size: larger;
  }
  .workspace {
    height: 600px;
  }
  .yzm {
    width: 100%;
    height: 200px;
    .yzm-title {
      font-size: x-large;
      font-weight: 600;
      text-align: center;
    }
    .yzm-num{
      font-size: xx-large;
      letter-spacing: 10px;
    }
  }
`;
