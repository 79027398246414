import styled from "styled-components";

export const Mainwrap = styled.div`
  .addonepoint{
    pointer-events:${props => props.canclick}
  }
  .titlebtn{
    size: 10vh;
  }
  .polyline{
    pointer-events:${props => props.canclick}
  }
  .polygon{
    pointer-events:${props => props.canclick}
  }
  .circle{
    pointer-events:${props => props.canclick}
  }
  .IPsearch{
    width: 800px;
  }

`;