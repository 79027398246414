import styled from "styled-components";

export const Mainwrap = styled.div`
  display: flex;
  position: absolute;
  flex-direction:column;
    .list{
        z-index: 1;
        background-color: white;
        
        
    }
    .btn{
        z-index: 1;
        width: 100px;
        left: 0.5vh;
    }
`;