import styled from "styled-components";

export const Mainwrap = styled.div`
  .workspace{
    display: flex;
    width:260px;
    margin-bottom: 10px;
    
    .imeiinput{
        width: 220px;
    }
    .imeioutput{
        width: 220px;
    }
    .changebtn{
        margin: 0px 5px 0px 5px;
    }  
  }
  .card{
        height: 130px;
        width: 260px;
    }
`;
