import React, { memo, useRef, useState } from "react";
import { Button, Input, message, Card,Space } from "antd";
import { Mainwrap } from "./style";
import http from "../../../services/request";

const Guishu = memo(() => {
  const inputref = useRef();
  const [value, setValue] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [types, settypes] = useState("");
  const [city, setcity] = useState("");

  const btnclick = () => {
    //号码输入框的值
    const inputvalue = inputref.current.input.value;
    // console.log(inputref)
    http({
      url: "phonetype",
      params: {
        mobile: inputvalue,
      },
    }).then((res) => {
        //code200成功
      if (res.ret === 200) {
        // console.log(res.data.types, res.data.prov, res.data.city);
        settypes(res.data.types);
        setcity(res.data.prov + res.data.city);
        messageApi.open({
            type: "success",
            content: "查询成功",
            duration: 3,
          });
      }else{
        messageApi.open({
            type: "warning",
            content: "查询失败",
            duration: 3,
          });
      }
    });
  };

  const handleChange = (e) => {
    const newValue = e.target.value.replace(/\D/g, '');
    setValue(newValue);
  };
  return (
    <Mainwrap>
      {contextHolder}
      <div className="workspace">
        <Input
        value={value}
          className="phoneinput"
          placeholder="输入11位手机号"
          onChange={handleChange}
          ref={inputref}
          maxLength={11}
          showCount
        />
        <Button type="primary" className="changebtn" onClick={btnclick}>
          查询
        </Button>
      </div>
      {/* <Card size="small" className="card"> */}
        <h3>运营商：{types}</h3>
        <h3>归属地：{city}</h3>
      {/* </Card> */}
    </Mainwrap>
  );
});

export default Guishu;
