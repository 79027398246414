import React, { memo, useEffect, useRef, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import muban from "../../../../img/muban.xls";
import { Upload, Card, Input, Radio, Button, message, Popover } from "antd";
import * as XLSX from "xlsx";
import { v4 as uuidv4 } from "uuid";
import gcoord from "gcoord";
import { useDispatch, useSelector } from "react-redux";
import { addlacaction } from "../../../../store/circleSlice";
import {
  changepointdata,
  changeshowplist_all,
  openshowplist,
  originalpointsave,
} from "../../../../store/mappointSlice";
import { Mainwrap } from "./style";
// import { uuid } from "uuidv4";

const { Dragger } = Upload;
const { TextArea } = Input;

//此处为上传框的配置

const Pointadd = memo((prop) => {
  const { fix } = prop;
  const [messageApi, contextHolder] = message.useMessage();
  const { originalpoint } = useSelector((store) => store.mappoint);
  //输入框的ref
  const dispatch = useDispatch();
  const inputref = useRef();
  //动态存储历史选中的坐标系

  //配置地图坐标系选择框,获取当前选中的元素
  const [radiovalue, setradioValue] = useState(
    sessionStorage.getItem("groodcheck") === null
      ? 1
      : sessionStorage.getItem("groodcheck")
  );
  //    console.log(radiovalue)

  const onChange = (e) => {
    // console.log("radio checked", e.target.value);
    setradioValue(e.target.value);
  };

  const addpoint = () => {
    //拿到当前选中的框的值
    let inputvalue = inputref.current.resizableTextArea.textArea.value;
    if (inputvalue == "") {
      messageApi.open({
        type: "info",
        content: "未输入数据",
        duration: 3,
      });
      return;
    }
    //对数据进行去除空格以及逗号修改
    let reg = new RegExp("，", "g");
    inputvalue = inputvalue.trim().replace(reg, ",");
    reg = new RegExp("/huiche", "g");
    inputvalue = inputvalue.replace(reg, "\n");
    const inputarr = inputvalue.split(/[\n]|\/huiche/);
    //   console.log(inputarr);

    //传入目标坐标系以及原始输入的经纬度作为参数传入函数，返回改变后的参数

    let changearr = gpschange(radiovalue, inputarr);
    // console.log(changearr)
    //遍历数据，查找是否需要画圆
    changearr.forEach((item) => {
      // console.log(item[2])
      if (item.length === 3 && item[2].indexOf("#yuan") != -1) {
        // const radius = item[2].split("#yuan")[1]
        const arr = item[2].split("#yuan");

        //    item[2]=item[2].split("#yuan")[0]
        item[2] = arr[0];
        //    console.log(item[2])
        // console.log(arr);

        dispatch(
          addlacaction({
            arr: [
              {
                id: uuidv4(),
                coord: [item[0] + "", item[1] + ""],
                radius: arr[1],
                type: "yuan",
              },
            ],
          })
        );
      }
    });
    // changearr = changearr.forEach(item => {
    //     arraddid.push( {
    //         latlng:[...item],
    //         id:uuidv4()
    //     })
    // });

    //console.log(arraddid)
    dispatch(changepointdata({ mappointlist: changearr }));
    console.log("inputvalue:",inputvalue)
    dispatch(originalpointsave({ point: inputvalue }));
    //打开点位列表
    dispatch(changeshowplist_all());
    //调用父组件中的函数关闭modal
    dispatch(openshowplist());
    //设置坐标系
    sessionStorage.setItem("groodcheck", radiovalue);
    !fix && prop.cancelpoint();
  };

  const props = {
    name: "excelfile",
    accept: ".xls , .xlsx",
    maxCount: 1,
    multiple: true,
    //解析上传的excel
    beforeUpload: (file, fileList) => {
      var rABS = true;
      const f = fileList[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        if (!rABS) data = new Uint8Array(data);
        var workbook = XLSX.read(data, {
          type: rABS ? "binary" : "array",
        });
        // 假设我们的数据在第一个标签
        var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
        // XLSX自带了一个工具把导入的数据转成json
        var jsonArr = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });
        jsonArr.splice(0, 1);
        // console.log(jsonArr);
        const jsonArrjoin = [];
        jsonArr.forEach((item) => {
          jsonArrjoin.push(item.join(","));
        });
        //此处需要根据底下选中的坐标系对坐标数据进行转化，然后通过redux进行派发
        let changearr = gpschange(radiovalue, jsonArrjoin);
        //处理excel数据时间格式问题
        changearr.forEach((item, index) => {
          if (parseInt(item[2]) > 40000 && parseInt(item[2]) < 50000) {
            //excel时间为文本，开始转化
            // 计算时间部分
            const days = Math.floor(item[2]);
            const milliseconds = Math.round((item[2] % 1) * 86400 * 1000);
            const date = new Date(
              Date.UTC(1899, 11, 30 + days, 0, 0, 0, milliseconds)
            );

            // 提取年月日时分秒
            const year = date.getUTCFullYear();
            const month = date.getUTCMonth() + 1; // 月份从 0 开始，所以需要加 1
            const day = date.getUTCDate();
            const hours = date.getUTCHours();
            const minutes = date.getUTCMinutes();
            const seconds = date.getUTCSeconds();

            // 构建时间字符串
            const dateString = `${year}-${month
              .toString()
              .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
            const timeString = `${hours.toString().padStart(2, "0")}:${minutes
              .toString()
              .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
            const fullTimeString = `${dateString} ${timeString}`;

            // console.log(fullTimeString);
            item[2] = fullTimeString;
          }
        });

        console.log(changearr);
        dispatch(changepointdata({ mappointlist: changearr }));
        dispatch(changeshowplist_all());
        dispatch(openshowplist());
      };
      if (rABS) reader.readAsBinaryString(f);
      else reader.readAsArrayBuffer(f);
      return false;
    },
  };

  // console.log(plist);

  //改变坐标系的函数
  const gpschange = (radiovalue, inputarr) => {
    console.log(inputarr);
    let changearr = [];
    let itemarr = [];
    switch (parseInt(radiovalue)) {
      case 1:
        //什么都不做
        // console.log(1)
        inputarr.forEach((item) => {
          changearr.push(item.split(",", 3));
          console.log(item.split(",", 3));
        });
        //  console.log(inputarr);
        console.log(changearr);
        return changearr;

      case 2:
        //执行火星坐标转百度坐标
        inputarr.forEach((item) => {
          itemarr = item.split(",", 3);
          //   console.log(itemarr)
          itemarr.length === 3
            ? changearr.push(
                gcoord
                  .transform(itemarr, gcoord.GCJ02, gcoord.BD09)
                  .concat(itemarr[2])
              )
            : changearr.push(
                gcoord.transform(itemarr, gcoord.GCJ02, gcoord.BD09)
              );
        });
        return changearr;
      case 3:
        //执行原始坐标转百度坐标
        inputarr.forEach((item) => {
          itemarr = item.split(",", 3);
          //   console.log(itemarr)
          itemarr.length === 3
            ? changearr.push(
                gcoord
                  .transform(itemarr, gcoord.WGS84, gcoord.BD09)
                  .concat(itemarr[2])
              )
            : changearr.push(
                gcoord.transform(itemarr, gcoord.WGS84, gcoord.BD09)
              );
        });
        return changearr;
      // inputarr.forEach(item => {
      //     changearr.push(
      //         gcoord.transform(item.split(","),gcoord.WGS84,gcoord.BD09)
      //     )
      // });
      default:
        break;
    }
  };
  return (
    <Mainwrap>
      {contextHolder}

      <div className="inputwrap">
        {/* 上传框 */}
        <a href={muban}>下载模板</a>
        <div className="upload">
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-hint">支持拖拽和点击上传excel</p>
          </Dragger>
        </div>
        {/* 示例参考格式 */}
        <Card title="参考格式如下：" size="small" className="cardtitle">
          <Card.Grid hoverable={false} className="cardgrid">
            lng(经度)
          </Card.Grid>
          <Card.Grid hoverable={false} className="cardgrid">
            lat(纬度)
          </Card.Grid>
          <Card.Grid hoverable={false} className="cardgrid">
            120.404
          </Card.Grid>
          <Card.Grid hoverable={false} className="cardgrid">
            27.915
          </Card.Grid>
        </Card>
        {/* 批量录入输入框 */}
        <p>批量录入</p>
        <TextArea
          defaultValue={originalpoint}
          rows={9}
          ref={inputref}
          placeholder="请输入经纬度，并使用回车分割，例如:
120.404, 39.914266
---支持添加备注，每行数据最后输入备注，以逗号分隔，例如：
120.404, 39.914266，落脚点
---点击地图点位可直接复制分享链接
"
        />
        {/* 选择坐标系的框 */}

        <Radio.Group
          onChange={onChange}
          value={parseInt(radiovalue)}
          size="small"
        >
          <Radio value={1}>百度</Radio>
          <Radio value={2}>火星(高德、QQ)</Radio>
          <Radio value={3}>原始</Radio>
        </Radio.Group>

        <Button type="primary" block onClick={addpoint}>
          打点
        </Button>
        {/* {shareurl == "烽哥牛逼" ? null : 

            <Card title="扫码导航" className="cardtitle">
            <Card.Grid hoverable={false} className="carderweima">
                <QRCode value={shareurl} className="carderweima1"/>
                </Card.Grid>
            </Card>
            } */}
      </div>
    </Mainwrap>
  );
});

export default Pointadd;
