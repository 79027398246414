import React, { memo, useRef, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import muban from "../../../img/lacmuban.xls";
import { Upload, Card, Input, Radio, Button, message, Modal } from "antd";
import * as XLSX from "xlsx";
import { v4 as uuidv4 } from "uuid";
import http from "../../../services/request";
import { useDispatch, useSelector } from "react-redux";
import {
  changepointdata,
  changeshowplist_all,
  openshowplist,
  changepointdatajizhan,
} from "../../../store/mappointSlice";
import { addlacaction } from "../../../store/circleSlice";
import { Mainwrap, Container, LeftElement } from "./style";

const { Dragger } = Upload;
const { TextArea } = Input;

//此处为上传框的配置

const Morelacany = memo((prop) => {
  const { fix, morelacCancel } = prop;
  const [messageApi, contextHolder] = message.useMessage();
  const { showplist } = useSelector((store) => store.mappoint);
  //输入框的ref
  const dispatch = useDispatch();
  const inputref = useRef(); //输入框
  const selectref = useRef(); //选择框
  const radioref = useRef();
  //获取当前选中的移动或者联通
  const [radiovalue, setradioValue] = useState(0);
  const [btnvalue, setbtnvalue] = useState(false);
  const [showcircle, setshowcircle] = useState(false);
  const onChange = (e) => {
    // console.log("radio checked", e.target.value);
    setradioValue(e.target.value);
  };

  const addanylac = () => {
    messageApi.open({
      type: "success",
      content: "基站查询中，请不要关闭该界面，耐心等待",
      duration: 3,
    });

    //禁用按钮
    setbtnvalue(true);
    //拿到当前选中的框的值
    let inputvalue = inputref.current.resizableTextArea.textArea.value;
    if (inputvalue == "") {
      messageApi.open({
        type: "info",
        content: "未输入数据",
        duration: 3,
      });
      return;
    }
    //对数据进行去除空格以及逗号修改
    let reg = new RegExp("，", "g");
    inputvalue = inputvalue.trim().replace(reg, ",");
    const inputarr = inputvalue.split("\n"); //所有输入的数据

    const lacarr = [];
    const inputarrtoarr = [];
    inputarr.forEach((item) => {
      //获取数组前两位的lac和lng信息
      lacarr.push(item.split(",").slice(0, 2));
      inputarrtoarr.push(item.split(","));
    });

    //这里是已经去重的数组
    let uniqueArr = Array.from(new Set(lacarr.map(JSON.stringify))).map(
      JSON.parse
    );

    let arrandid = [];
    uniqueArr.forEach((item, index) => {
      arrandid.push({
        lac: item,
        id: uuidv4(),
        bz: item,
      });
    });
    // console.log(arrandid)
    //拿到解析之后的经纬度
    let dadianarr = [];
    // console.log("arrandid",arrandid)
    jiexi(arrandid, radiovalue).then((res) => {
      //    console.log(res)
      if (res.error.length > 0) {
        // console.log("有无法解析的基站，dosomething")
      }
      inputarrtoarr.forEach((item, index) => {
        arrandid.forEach((element) => {
          // console.log(element)
          //找到所有的数据
          if (item[0] === element.lac[0] && item[1] === element.lac[1]) {
            // console.log("匹配到数据")
            res.success.forEach((resitem) => {
              if (resitem.id === element.id) {
                inputarrtoarr[index][0] = resitem.coord[0];
                inputarrtoarr[index][1] = resitem.coord[1];
                dadianarr.push({
                  arr: inputarrtoarr[index],
                  type: "基站查询",
                  bz: element.lac.join(","),
                  radius: resitem.radius,
                });
              }
            });
          }
        });
      });
      //   console.log(res.success)
      // let pointarr = Array.from(new Set(dadianarr.arr.map(JSON.stringify))).map(
      //     JSON.parse
      //   );
      //   console.log(dadianarr)

      if (showcircle) {
        // dispatch圆
        dispatch(
          addlacaction({
            arr: res.success,
          })
        );
      }
      dispatch(changepointdatajizhan({ mappointlist: dadianarr }));
      //打开点位列表
      dispatch(changeshowplist_all());
      //调用父组件中的函数关闭modal
      dispatch(openshowplist());
      // console.log("无法解析的基站有",res.error)
      //解除禁用按钮
      setbtnvalue(false);
      morelacCancel();
      let str = "";
      res.error.forEach((item) => {
        // console.log(item)
        str = "lac:" + item.lac + "," + item.ci + "未查询到数据";
        messageApi.open({
          type: "info",
          content: str,
          duration: 10,
        });
      });
    });

    !fix && prop.morelacCancel();
  };

  const jiexi = async (lacarr, type) => {
    // console.log("lacarr:",lacarr)
    let arrlac = []; //存放解析之后经纬度的数组
    let errarr = [];
    for (const item of lacarr) {
      await http({
        url: "lbsgps",
        params: {
          mcc: 460,
          mnc: type,
          lac: item.lac[0],
          ci: item.lac[1],
          coord: "bd09",
          output: "json",
        },
      }).then(
        (response) => {
          //    console.log(response)
          if (parseInt(response.errcode) === 0) {
            //查询成功
            arrlac.push({
              coord: [response.lon, response.lat],
              id: item.id,
              radius: response.radius,
            });
          } else {
            errarr.push({
              lac: item.lac[0],
              ci: item.lac[1],
              id: item.id,
            });
          }
        },
        (error) => {
          console.log("错误", error.message);
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 3,
          });
        }
      );
    }
    // console.log(arrlac,errarr)
    const result = {
      success: arrlac,
      error: errarr,
    };
    return result;
  };
  const props = {
    name: "excelfile",
    accept: ".xls , .xlsx",
    maxCount: 1,
    multiple: true,
    //解析上传的excel
    beforeUpload: (file, fileList) => {
      var rABS = true;
      const f = fileList[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        if (!rABS) data = new Uint8Array(data);
        var workbook = XLSX.read(data, {
          type: rABS ? "binary" : "array",
        });
        // 假设我们的数据在第一个标签
        var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
        // XLSX自带了一个工具把导入的数据转成json
        var jsonArr = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });
        jsonArr.splice(0, 1);
        // console.log(jsonArr);
        const inputarr = [];
        jsonArr.forEach((item) => {
          inputarr.push(item.join(","));
        });
        //此处需要根据底下选中的坐标系对坐标数据进行转化，然后通过redux进行派发

        const lacarr = [];
        const inputarrtoarr = [];
        inputarr.forEach((item) => {
          //获取数组前两位的lac和lng信息
          lacarr.push(item.split(",").slice(0, 2));
          inputarrtoarr.push(item.split(","));
        });
        // console.log("inputarrtoarr:",inputarrtoarr)
        //这里是已经去重的数组
        let uniqueArr = Array.from(new Set(lacarr.map(JSON.stringify))).map(
          JSON.parse
        );

        let arrandid = [];
        uniqueArr.forEach((item, index) => {
          arrandid.push({
            lac: item,
            id: uuidv4(),
          });
        });
        // console.log(arrandid)
        //拿到解析之后的经纬度
        let dadianarr = [];
        console.log("arrandid", arrandid);
        jiexi(arrandid, radiovalue).then((res) => {
          // console.log(res)
          if (res.error.length > 0) {
            console.log("有无法解析的基站，dosomething");
          }
          inputarrtoarr.forEach((item, index) => {
            arrandid.forEach((element) => {
              // console.log(element)
              //找到所有的数据
              if (item[0] === element.lac[0] && item[1] === element.lac[1]) {
                // console.log("匹配到数据")
                res.success.forEach((resitem) => {
                  if (resitem.id === element.id) {
                    inputarrtoarr[index][0] = resitem.coord[0];
                    inputarrtoarr[index][1] = resitem.coord[1];
                    dadianarr.push(inputarrtoarr[index]);
                  }
                });
              }
            });
          });
          let pointarr = Array.from(new Set(dadianarr.map(JSON.stringify))).map(
            JSON.parse
          );

          if (showcircle) {
            // dispatch圆
            dispatch(
              addlacaction({
                arr: res.success,
              })
            );
          }

        //   console.log(pointarr)
        //处理excel数据时间格式问题
          pointarr.forEach((item, index) => {
            if (parseInt(item[2])>40000&&parseInt(item[2])<50000) {
              //excel时间为文本，开始转化
              // 计算时间部分
              const days = Math.floor(item[2]);
              const milliseconds = Math.round((item[2] % 1) * 86400 * 1000);
              const date = new Date(
                Date.UTC(1899, 11, 30 + days, 0, 0, 0, milliseconds)
              );
  
              // 提取年月日时分秒
              const year = date.getUTCFullYear();
              const month = date.getUTCMonth() + 1; // 月份从 0 开始，所以需要加 1
              const day = date.getUTCDate();
              const hours = date.getUTCHours();
              const minutes = date.getUTCMinutes();
              const seconds = date.getUTCSeconds();
  
              // 构建时间字符串
              const dateString = `${year}-${month
                .toString()
                .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
              const timeString = `${hours.toString().padStart(2, "0")}:${minutes
                .toString()
                .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
              const fullTimeString = `${dateString} ${timeString}`;
  
              // console.log(fullTimeString);
              item[2] = fullTimeString;
            }
          });


          dispatch(
            changepointdata({
              mappointlist: pointarr,
            })
          );
          //打开点位列表
          dispatch(changeshowplist_all());
          //调用父组件中的函数关闭modal
          dispatch(openshowplist());
          // console.log("无法解析的基站有",res.error)
          let str = "";
          res.error.forEach((item) => {
            // console.log(item)
            str = "lac:" + item.lac + "," + item.ci + "未查询到数据";
            messageApi.open({
              type: "info",
              content: str,
              duration: 10,
            });
          });
        });
      };
      if (rABS) reader.readAsBinaryString(f);
      else reader.readAsArrayBuffer(f);
      return false;
    },
  };
  const clickshow = () => {
    setshowcircle(!showcircle);
    // console.log(radioref.current.state.checked)
  };
  return (
    <Mainwrap>
      {contextHolder}

      <div className="inputwrap">
        {/* 上传框 */}
        <a href={muban}>下载模板</a>
        <div className="upload">
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-hint">支持拖拽和点击上传excel</p>
          </Dragger>
        </div>
        {/* 示例参考格式 */}
        <Card title="参考格式如下：" size="small" className="cardtitle">
          <Card.Grid hoverable={false} className="cardgrid">
            lac
          </Card.Grid>
          <Card.Grid hoverable={false} className="cardgrid">
            lng
          </Card.Grid>
          <Card.Grid hoverable={false} className="cardgrid">
            22522
          </Card.Grid>
          <Card.Grid hoverable={false} className="cardgrid">
            11111111
          </Card.Grid>
        </Card>
        {/* 批量录入输入框 */}
        <Container>
          <LeftElement>批量录入</LeftElement>
          <Radio onClick={clickshow} checked={showcircle} ref={radioref}>
            显示基站范围（仅供参考）
          </Radio>
        </Container>
        <TextArea
          rows={9}
          ref={inputref}
          placeholder="请输入基站lac和lng，并使用回车分割，例如:
22513, 11111111
---支持添加备注，每行数据最后输入备注，以逗号分隔，例如：
22513, 11111111，2023-1-1 16:16:16
---点击地图点位可直接复制分享链接
---建议一次录入不要超过100条
"
        />
        {/* 选择坐标系的框 */}
        <Radio.Group
          onChange={onChange}
          value={radiovalue}
          size="small"
          ref={selectref}
        >
          <Radio value={0} defaultChecked>
            移动
          </Radio>
          <Radio value={1}>联通</Radio>
        </Radio.Group>
        <Button type="primary" block onClick={addanylac} disabled={btnvalue}>
          添加解析
        </Button>
      </div>
    </Mainwrap>
  );
});

export default Morelacany;
