import styled from "styled-components";

export const Mainwrap = styled.div`
  display: flex;
  
  .inputwrap {
    flex: 1;
    margin: 5px 0px 5px 5px;
  }
  .mapwrap{
    flex: 4.5;
    margin: 5px 5px 5px 5px;
  }
  .upload{
    height: 130px;
  }
  .cardtitle{
    font-size: small;
    text-align:center;
    vertical-align: middle;
  }
  .cardgrid{
    width: 50%;
    text-align:center;
    height:20%;
    padding: 7px;
  }
  .carderweima{
    align-items: center;
    width: 100%;
    height: 190px;
  }
  .carderweima1{
    position: absolute;
    left: 20%;
  }
`;