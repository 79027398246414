import React, { memo } from "react";
import { Mainwrap } from "./style";
const Footerba = memo(() => {
  return (
    <Mainwrap>
      <div className="beian">
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
          浙ICP备2023007236号-1 V2.0
        </a>
      </div>
    </Mainwrap>
  );
});

export default Footerba;
