import React, { memo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button, Input, List, message, Modal, Popconfirm, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Mainwrap } from "./style";
import moment from "moment";
import { savecircleaction } from "../../../store/circleSlice";
import { savelineaction } from "../../../store/lineSlice";
import { savepointaction } from "../../../store/mappointSlice";
import { savepolygonaction } from "../../../store/polygonSlice";
import axios from "axios";
import { xcxapi } from "../../../services/config";
import dayjs from "dayjs";

const Save = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const success = () => {
    messageApi.open({
      type: "success",
      content: "数据已添加到地图",
    });
  };
  const dispatch = useDispatch();
  //拿到存在localstorge中的本地数据
  //   const [hisdata, sethisdata] = useState(
  //     JSON.parse(window.localStorage.getItem("data"))
  //   );
  let hisdata = JSON.parse(window.localStorage.getItem("data"));
  //初始化data的值
  const [data, setdata] = useState(
    hisdata?.dataarr === undefined ? [] : hisdata.dataarr
  );
  if (hisdata?.dataarr === undefined) {
    hisdata = {
      dataarr: [],
    };
  }

  const allstore = useSelector((store) => store);
  const inputref = useRef();
  const shareref = useRef();
  //拿到所有的数据
  //添加数据
  const savealldata = () => {
    let inputvalue = inputref.current.input.value; //拿到input中保存的数据
    if (inputvalue === "") {
      inputvalue = "未命名";
    }
    //拿到之前存储的所有数据
    const time = moment().format("YYYY-MM-DD HH:mm:ss");
    // console.log(hisdata)
    allstore.id = uuidv4();
    //第一次存储，直接保存
    allstore.savetime = time;
    allstore.bz = inputvalue;
    console.log(hisdata);
    hisdata.dataarr.unshift(allstore);
    setdata([...hisdata.dataarr]);

    // console.log(data)
    //添加数据
    const strdata = JSON.stringify(hisdata);
    //更新数据
    window.localStorage.setItem("data", strdata);
    message.success("保存成功");
    // console.log(hisdata)
  };
  //显示所有的数据
  const showlistdata = (item) => {
    //保存数据
    dispatch(savecircleaction({ circle: item.circle }));
    dispatch(savelineaction({ line: item.line }));
    dispatch(savepointaction({ point: item.mappoint }));
    dispatch(savepolygonaction({ polygon: item.polygon }));
    // console.log(item.   )
    success();
  };
  const deletedata = (item) => {
    hisdata.dataarr.forEach((element, index) => {
      if (item.id === element.id) {
        hisdata.dataarr.splice(index, 1);
      }

      setdata([...hisdata.dataarr]);
      const strdata = JSON.stringify(hisdata);
      //更新数据
      window.localStorage.setItem("data", strdata);
    });
    message.success("已删除");
  };
  //导出json数据
  const exportjson = (item) => {
    //这个是按钮点击执行的函数
    const jsondata = JSON.stringify(item); //这里是json数据
    const blob = new Blob([jsondata], { type: "application/json" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = item.bz;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };
  //导入json数据
  const uploadjson = async () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "application/json";

    const file = await new Promise((resolve, reject) => {
      input.onchange = () => {
        const file = input.files[0];
        if (!file) {
          reject(new Error("请选择要上传的文件"));
          return;
        }
        resolve(file);
      };
      input.click();
    });

    const reader = new FileReader();
    reader.readAsText(file, "utf-8");

    const data = await new Promise((resolve, reject) => {
      reader.onload = () => {
        try {
          const data = JSON.parse(reader.result);
          resolve(data);
        } catch (e) {
          reject(new Error("文件格式不正确"));
        }
      };
      reader.onerror = () => {
        reject(new Error("读取文件出错"));
      };
    });
    // 在这里处理上传的数据
    hisdata.dataarr.unshift(data);
    setdata([...hisdata.dataarr]);

    const strdata = JSON.stringify(hisdata);
    //更新数据
    window.localStorage.setItem("data", strdata);
    console.log(data);
  };
  //云分享输入框
  const cloudshare = async () => {
    setIsModalOpen(true);
  };

  //输入云分享码之后显示数据
  const getdatebyshareid = async () => {
    let sharevalue = shareref.current.input.value;
    axios
      .post(`${xcxapi}/map/getdatebyshareid`, {
        shareid: sharevalue,
      })
      .then((res) => {
        if (res.data.type === "success") {

          //本地保存一份

          const savedate={
            id:res.data.data._id,
            savetime:moment(res.data.data.createdAt).format("YYYY-MM-DD HH:mm:ss"),
            bz:`${res.data.data.dataname}--[${res.data.data.user}  分享]`,
            circle:JSON.parse(res.data.data.data).circle,
            line:JSON.parse(res.data.data.data).line,
            mappoint:JSON.parse(res.data.data.data).mappoint,
            polygon:JSON.parse(res.data.data.data).polygon,
            user:{
              islogin:true,
              user:{
                openid:res.data.data.openid,
                name:res.data.data.user,
              }
            }
          }
          hisdata.dataarr.unshift(savedate);
          setdata([...hisdata.dataarr]);
          const strdata = JSON.stringify(hisdata);
          //更新数据
          window.localStorage.setItem("data", strdata);
          // console.log(res.data.data)


          const objstr = JSON.parse(res.data.data.data);
          // console.log("res:",objstr)
          dispatch(savecircleaction({ circle: objstr.circle }));
          dispatch(savelineaction({ line: objstr.line }));
          dispatch(savepointaction({ point: objstr.mappoint }));
          dispatch(savepolygonaction({ polygon: objstr.polygon }));

          setIsModalOpen(false);

          message.success(
            `来自 ${res.data.data.user} 的分享，标题:${res.data.data.dataname}`
          );
        } else if (res.data.type === "fail") {
          message.error(res.data.msg);
        }
      });
  };

  //更新当前的数据
  const updata = async (item) => {
    hisdata.dataarr.forEach((element, index) => {
      if (item.id === element.id) {
        // console.log("element:", element);
        hisdata.dataarr[index].circle = allstore.circle;
        hisdata.dataarr[index].line = allstore.line;
        hisdata.dataarr[index].mappoint = allstore.mappoint;
        hisdata.dataarr[index].polygon = allstore.polygon;
        hisdata.dataarr[index].savetime = moment().format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }

      setdata([...hisdata.dataarr]);
      const strdata = JSON.stringify(hisdata);
      // //更新数据
      window.localStorage.setItem("data", strdata);
    });
  };
  return (
    <Mainwrap>
      {contextHolder}
      <div className="top">
        <Space size={"small"}>
          <Input placeholder="保存名称" className="bz" ref={inputref} />

          <Button type="primary" onClick={savealldata}>
            保存
          </Button>
          <Button type="dashed" className="upload" onClick={() => uploadjson()}>
            导入
          </Button>
          <Button
            type="primary"
            className="upload"
            onClick={() => cloudshare()}
          >
            提取
          </Button>
        </Space>
      </div>
      <List
        className="list"
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              <a
                key="list-loadmore-edit"
                onClick={() => showlistdata(item, index)}
              >
                显示
              </a>,

              <a key="list-loadmore-more" onClick={() => exportjson(item)}>
                导出
              </a>,
              <Popconfirm
                title="确定将当前的图上数据覆盖至本条记录？此操作不可逆！！！"
                onConfirm={() => updata(item, index)}
                // onCancel={cancel}
                okText="更新"
                cancelText="取消"
              >
                <a key="list-loadmore-more">更新</a>
              </Popconfirm>,
              <Popconfirm
                title="确定删除吗？"
                onConfirm={() => deletedata(item, index)}
                // onCancel={cancel}
                okText="删除"
                cancelText="取消"
              >
                <a key="list-loadmore-more">删除</a>
              </Popconfirm>,
            ]}
          >
            <List.Item.Meta
              title={item.bz}
              description={`更新于：${item.savetime}`}
            />
          </List.Item>
        )}
        pagination={{
          total: data.length,
          align: "center",
          pageSize: 8,
        }}
      />
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        cancelText={"取消"}
        okText={"确定"}
        onOk={() => getdatebyshareid()}
        destroyOnClose={true}
      >
        <Input
          placeholder="在此输入分享码"
          className="shareinput"
          ref={shareref}
          style={{ width: "340px" }}
        />
      </Modal>
    </Mainwrap>
  );
};

export default Save;
