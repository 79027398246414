import styled from "styled-components";
import jt from "../../img/jt.png"
export const Mainwrap = styled.div`
  .App {
    text-align: center;
  }
  .header {
    height: 5vh;
    display: flex;
    align-items: center;
  }
  .funcset {
    border-radius: 5px;
    margin-left: auto;
    margin-right: 10px;
    
  }
  .div1 {
    display: flex;
    margin-left: auto;
    align-items: center;
  }
  .shouce {
    margin-left: auto;
    margin-right: 10px;
  }
  .question{
    color: white;
    margin-right: 5px;
  }
  .logo {
    /* background-image: url("./img/bit.png"); */
    /* background-size: 100% 100%; */
    width: 80px; /* 设置宽度 */
    height: 80px; /* 设置高度 */
    background-repeat: no-repeat; /* 防止图片重复 */
    background-size: contain; /* 控制背景图片的大小 */
  }
  .title {
    width: 140px; /* 设置宽度 */
    height: 5vh; /* 设置高度 */
    color: white;
    font-size: 30px;
    display: flex;
    align-items: center;
    background-image:url(${jt});
    background-size: 90% 90%;
    background-repeat:no-repeat ;
    margin-top: 5px;
  }
  .imeiinput{
    width: 180px;
  }

  .time {
    margin-left: auto;
    color: white;
    font-size: 15px;
    align-items: center;
  }

  .title-c {
    width: 450px; /* 设置宽度 */
    height: 80px; /* 设置高度 */
    color: white;
    font-size: 20px;
    display: flex;
    align-items: center;

  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .modal-body {
    font-size: 130px;
  }
  .login-btn{
    margin-left: 10px;
  }
  .login{
    height: 800px;
  }
  .avatar{
    background-color: #108ee9;
    margin-left: 20px;
  }
`;
