import styled from "styled-components";

export const Mainwrap = styled.div`

    .time1{
        width: 180px;
    }
    .time2{
        width: 180px;
    }
    .btn{
        margin:  0 5px  0 5px ;
    }
    .xiangcha{
        text-align: center;
    }
  
`;