import styled from "styled-components";

export const Mainwrap = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;

  .top {
    display: flex;
    .bz {
      width: 200px;
      flex: 1.5;
    }
    .savebtn {
      width: 120px;
      flex: 1;
    }
    .upload {
      flex: 0.5%;
    }
  }
  .list {
    flex-direction: column;
  }
  h1{
    align-items:center;
    text-align: center;
  }
`;
