import { v4 as uuidv4 } from "uuid";
import {
  updataminaction,
  addoneaction,
  isdrawover,
} from "../../../../store/circleSlice";

export const circledraw = (map, dispatch, setaddone, ) => {
  // const { showlinelist } = useSelector((store) => store.line);
  const BMap = window.BMapGL;
  //创建一个当前点位的id
  let id = uuidv4();
  //临时存储第一个点的数据
  const firstpoint = []
  let clicknum =0

  function click(e) {
    clicknum+=1
    if(clicknum===1){
        const { lat, lng } = e.latlng;
        const point = [lat, lng];
        //保存第一个点的数据
        firstpoint.push(lat, lng)
        dispatch(
          addoneaction({
            onepoint: point,
            id: id,
          })
        );
    }
    
    // console.log("函数中clicknum的值为", clicknum);
    if (clicknum === 2) {
        // console.log("画圆完成，取消所有地图点击事件")
      map.removeEventListener("click", click);
      map.removeEventListener("mousemove", mousemove);
    //   map.removeEventListener("rightclick", rightclick);
      setaddone("auto");
      dispatch(
        isdrawover({
          id: id,
        })
      );
    }
  }


  function mousemove(e) {
    const { lat, lng } = e.latlng;
    const firstp = new BMap.Point(firstpoint[1],firstpoint[0])
    const endp = new BMap.Point(lng,lat)
    // console.log("第一个点的经纬度",firstpoint[1],firstpoint[0])
    // console.log("第二个点的经纬度",lng,lat)
    const distance= map.getDistance(firstp,endp)


    dispatch(
      updataminaction({
        //传入的数据为距离
        distance: distance,
        endclickp:[lat,lng],
        id: id,
      })
    );
  }

  // function rightclick(e){
  //     map.removeEventListener("click", click);
  //     map.removeEventListener("mousemove", mousemove);
  //     map.removeEventListener("rightclick", rightclick);
  //     setaddone("auto")

  //     dispatch(dellasttwo({
  //         id:id
  //     }))
  //     //完成之后开启可用
  // }

  map.addEventListener("click", click);

  //   map.addEventListener("rightclick",rightclick);

  map.addEventListener("mousemove", mousemove);
};
