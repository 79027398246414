import styled from "styled-components";

export const Mainwrap = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999; /* 确保该元素浮在其他元素上面 */
  a{
    text-decoration: none;
    font-size: small;
  }
`;