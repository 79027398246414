import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, TextArea, QRCode, message, Divider, Image } from "antd";
import { Mainwrap } from "./style";
import { xcxURL } from "../../../services/config";
import axios from "axios";
import { userloginsuccess } from "../../../store/userSlice";
import { get } from "fingerprintjs";
import fingerprint from "../../../utils/fingerprint";
// import taro from "taro";

const UserQRlogin = memo((props) => {
  const dispatch = useDispatch();

  const [messageApi, msgHolder] = message.useMessage();
  //登录码
  const [loginKey, setloginKey] = useState("");

  const [imageurl, setimageurl] = useState("");
  useEffect(() => {
    // axios.get('http://localhost:7778/api/reg/test')
    const wss = new WebSocket(`${xcxURL}/login/webqrcodelogin`);
    wss.onopen = (ws) => {
      // console.log("成功建立连接");
      // messageApi.open({
      //   type: "success",
      //   content: "连接成功，请打开侦迹小程序输入登录码完成登录",
      //   duration: 5,
      // });
      fingerprint().then((res) => {
        // console.log(res)
        wss.send(
          JSON.stringify({
            finger: res,
            type: "finger",
          })
        );
      });
    };
    wss.onmessage = (res) => {
      console.log(JSON.parse(res.data));
      const data = JSON.parse(res.data);
      // setloginKey
      if (data.type === "yzm") {
        setloginKey(data.data.yzm);
      }

      //返回当前的loginid，用于持久化的登录存储
      if (data.type === "loginid") {
        // console.log("保存数据到localStorage");
        localStorage.setItem("loginid", data.loginid);
      }

      if (data.type === "loginhistorytrue") {
        console.log("历史登录未过期，登录成功");
      }
      //登录成功
      if (data.type === "login") {
        dispatch(
          userloginsuccess({
            openid: data.data.openid,
            name: data.data.name,
          })
        );
        //把modle关闭
        props.setloginshowFalse();
      }

      if (data.type === "qrcode") {
        // 将Uint8Array转换为Base64编码的字符串

        const base64Data = btoa(String.fromCharCode(...data.qrcode.data));
        // console.log(base64Data);
        setimageurl(base64Data);
      }
    };
    wss.onclose = () => {
      console.log("连接被断开");
    };

    return () => {
      URL.revokeObjectURL(imageurl);
    };
  }, []);

  return (
    <Mainwrap>
      {msgHolder}
      <div className="workspace">
        <Divider></Divider>
        <div className="yzm">

          <p className="yzm-title yzm-num">{loginKey}</p>
          <Image src={`data:image/jpeg;base64,${imageurl}`} className="qrcode"/>
        </div>
        <Divider></Divider>
        <div className="tishi">
          <p>
            请打开微信扫码完成登录（未注册用户需要注册后才可以使用）
          </p>
        </div>
      </div>
    </Mainwrap>
  );
});

export default UserQRlogin;
