import styled from "styled-components";

export const Mainwrap = styled.div`
  position: absolute;
  z-index: 1;
  top: 8vh;
  right: 100PX;
  .input1 {
    width: 200px;
    height: 40px;
  }
  .searchResultPanel {
    border: 1px solid #c0c0c0;
    width: 150px;
  }
  .suggestId {
    z-index: 9999999999999999;
  }
`;
