import styled from "styled-components";

export const Mainwrap = styled.div`
  display: flex;
  flex-direction: row;
  .left{
    flex: 7;
  }

  .right{
    flex:20
  }
  .centerbtn{
    flex:3;
    text-align: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
  }
  .bz{
    width: 180px;
  }

  

`;
