import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "antd";
const Main = memo(() => {
  const navigate = useNavigate();

  const projectclick = () => {
    navigate("/project");
  };
  const projecconfigtclick = () => {
    navigate("/projectconfig");
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <button style={{ lineHeight: '50px', width: '150px', height: '50px' ,marginTop:'400px'}} onClick={projectclick} type="primary">专案显示</button>
      </div>
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <button style={{ lineHeight: '50px', width: '150px', height: '50px',marginTop:'400px' }} onClick={projecconfigtclick} type="primary">专案配置</button>
      </div>
    </div>
  );
});

export default Main;
