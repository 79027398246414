import { createSlice } from "@reduxjs/toolkit";
// import { v4 as uuidv4 } from "uuid";
export const lineSlice = createSlice({
  name: "lineSlice",
  initialState: {
    showlinelist: [],
  },
  reducers: {
    //右击之后完成画线
    dellasttwo(state, action) {
        state.showlinelist.forEach((item,index) => {
            if (item.id === action.payload.id) {
               state.showlinelist[index].point.pop()
               state.showlinelist[index].isover=true
            }
          });
      // console.log(action.payload.mappointlist)
    },
    //点击之后添加一个折线点位
    addoneaction(state, action) {
        // console.log("执行+1")
      let i = 0;
      state.showlinelist.forEach((item,index) => {
        if (item.id === action.payload.id) {
            state.showlinelist[index].point.push(action.payload.onepoint);
          i = i + 1;//这里通过修改i的值，让下面的代码不执行
        //   console.log("不是第一次添加线")
        }
      });

      //第一次添加数据的时候showlinelist里面是空的，没有任何数据的时候，就执行下面这句话添加一条新数据
      if (i === 0) {
        //  console.log("第一次添加线")
         state.showlinelist.push({
          id: action.payload.id,
          point: [action.payload.onepoint],
        });
      }
    //   console.log(i)
      // console.log(action.payload.mappointlist)
    },
    //配置移动变化折线
    updataminaction(state, action) {
      state.showlinelist.forEach((item, index) => {
        //寻找需要改变的数据
        if (item.id === action.payload.id) {
            //如果数据的长度为1，那就人为添加1，起到动态的效果
          const max = state.showlinelist[index].point.length;
        //   console.log(max)
          if (max > 1) {
            state.showlinelist[index].point[max - 1] = action.payload.lastpoint;
          } else{
            state.showlinelist[index].point[1] = [];
          }
        }
      });

      // console.log(action.payload.mappointlist)
    },
    dellineaction(state, action){
        state.showlinelist.forEach((item,index)=>{
            if(item.id===action.payload.id){
                state.showlinelist.splice(index,1)
            }
        })
    },
    //线画完之后更新的代码
    lineedit(state,action){
        // console.log(action.payload)
        state.showlinelist.forEach((item,index)=>{
            
            if(item.id===action.payload.id&&state.showlinelist[index].isover===true){
                state.showlinelist[index].point=action.payload.point
            }
        })
    },
    savelineaction(state,action){
        // 保存所有的数据
       state.showlinelist=action.payload.line.showlinelist
    }
  },
});

export const { dellasttwo, addoneaction, updataminaction,dellineaction,lineedit,savelineaction } =
  lineSlice.actions;
export default lineSlice.reducer;
