import React, { memo } from 'react'
import Headerfile from './header'
import Mainmap from "./body"
const Project = memo(() => {
  return (
    <div>
        <Headerfile/>
        <Mainmap/>
    </div>
  )
})

export default Project