import React, { memo, useRef, useState } from "react";
import { Input, Select, Button, message } from "antd";
import { Mainwrap } from "./style";
import http from "../../../services/request";
import { addonepointaction, updataonpoint,changeshowplist_all } from "../../../store/mappointSlice";
import { v4 as uuidv4 } from "uuid";
import { copyPageUrl } from "../../../utils/copydata";
import { useDispatch } from "react-redux";

// const {
//   convertBdMC2LL,
//   convertBdLL2MC,
//   transformWGS2GCJ,
//   transformGCJ2WGS,
//   baiduTomars,
//   marsTobaidu,
// } = maptools;

const selectoption = [
  {
    value: 0,
    label: "移动",
  },
  {
    value: 1,
    label: "联通",
  },
];

const LBS = memo((props) => {
    const {setlbsModalOpen}=props
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  //临时存储选中的select的值
  const [selectvalue, setselectvalue] = useState(0);

  const selectref = useRef();

  const lacref = useRef();
  const cidref = useRef();
  const bzref = useRef();

  const searchlbs = async () => {

    //取得输入框中的值
    const lac = lacref.current.input.value;
    const cid = cidref.current.input.value;
    const bz = bzref.current.input.value;

    const lbsdata = await http({
      url: "lbsgps",
      params: {
        mcc: 460,
        mnc: selectvalue,
        lac: lac,
        ci: cid,
        coord: "bd09",
        output: "json",
      },
    }).then(
      (response) => {
        console.log(response)
        if (response.errcode === 0) {
          const id = uuidv4();
          const bzvalue = lac + " " + cid;
          console.log(response)
        //   console.log(response.address, response.lat, response.lon);
          const onepoint = {
            latlng: [response.lon, response.lat],
            id: id,
          };
          //添加基站点位数据到地图中
          dispatch(addonepointaction({ onepoint: onepoint }));
          if (bz == "") {
            dispatch(
              updataonpoint({
                remarks: bzvalue,
                id: id,
              })
            );
            setlbsModalOpen(false)
            dispatch(changeshowplist_all())
          } else {
            dispatch(
              updataonpoint({
                remarks: bz,
                id: id,
              })
            );
            setlbsModalOpen(false)
            dispatch(changeshowplist_all())
          }
          
        } else {
          messageApi.open({
            type: "success",
            content: "未查询到基站数据",
            duration: 3,
          });
        }
      },
      (error) => {
        console.log("错误", error.message);
        messageApi.open({
          type: "success",
          content: error.message,
          duration: 3,
        });
      }
    );

    //复制转化后的值
    // copyPageUrl(restr,messageApi,"转化后经纬度已经复制")
  };

  //动态设置选中数据的值
  const savevalue = (value) => {
    setselectvalue(value);
  };

  return (
    <Mainwrap>
      {contextHolder}

      <div className="divclass">
        <div>
          <Select
            className="select"
            options={selectoption}
            defaultValue={0}
            ref={selectref}
            onChange={savevalue}
            
          ></Select>
        </div>
        <Input className="laclng" placeholder="LAC" ref={lacref} />
        <Input className="cid" placeholder="CID" ref={cidref} />
        <Input className="laclng" placeholder="备注" ref={bzref} />
        <Button type="primary" onClick={searchlbs}>
          定位
        </Button>
      </div>
    </Mainwrap>
  );
});

export default LBS;
