import React, { memo, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dellineaction,
  lineedit,
  savelineaction,
} from "../../../../store/lineSlice";
import {
  delpolygonaction,
  polygonedit,
  savepolygonaction,
} from "../../../../store/polygonSlice";
import {
  delcircleaction,
  circleedit,
  savecircleaction,
} from "../../../../store/circleSlice";
import {
  deletepointaction,
  updataonpoint,
  showprojectpoint,
} from "../../../../store/mappointSlice";
import { message } from "antd";
import http from "../../../../services/request";
import gcoord from "gcoord";
import { useDidUpdateEffect } from "../../../../utils/hooks/useDidUpdateEffect";
import Listdata from "../../../btnapp/list";
// import Btnapp from "../btnapp";
// import Search from "../btnapp/search";
import { Mainwrap } from "./style";

import { copyPageUrl } from "../../../../utils/copydata";
import { json } from "react-router";

const Mainmap = memo(() => {
  const mapref = useRef();
  //获取地图点位数据
  const [messageApi, daohangHolder] = message.useMessage();
  const { pointlist, showplist_all } = useSelector((store) => store.mappoint);
  const { showlinelist } = useSelector((store) => store.line);
  const { showgonlist } = useSelector((store) => store.polygon);
  const { showcircle } = useSelector((store) => store.circle);

  const [map, setmap] = useState();

  const BMap = window.BMapGL;

  const dispatch = useDispatch();

  const getprojectinter = setInterval(() => {
    http.post("project/getall",
    {
        setzero: true
    }).then((response) => {
    //   console.log(response);
    //   console.log(response.data.draw)
      if (response.msg === "success") {
        messageApi.open({
            type: 'success',
            content: '有新数据',
          });
        // console.log(response)
        const draw = response.data.draw[0];
        
        // console.log(circle,line,polygon)
        const point = response.data.point;
        const realpoint = point.map((obj) => {
          obj.latlng = JSON.parse(obj.latlng);
          return obj;
        });
        if(draw && 'circle' in draw){
            const circle = JSON.parse(draw.circle);
            dispatch(savecircleaction({ circle: circle }));
        }
        if(draw &&'line' in draw){
            const line = JSON.parse(draw.line);
            dispatch(savelineaction({ line: line }));
        }
        if(draw &&'polygon' in draw){
            const polygon = JSON.parse(draw.polygon);
            dispatch(savepolygonaction({ polygon: polygon }));
        }
       

        
        
        
        dispatch(showprojectpoint({ point: realpoint }));
        clearInterval(getprojectinter);
      }
      //   console.log(point);
    });

    
  }, 10000);

  //地图的加载
  useEffect(() => {
    const map = new BMap.Map(mapref.current);
    let point = new BMap.Point(120.697402, 28.140707); //创建一个点
    map.centerAndZoom(point, 16);
    map.enableScrollWheelZoom(true);
    const scaleCtrl = new BMap.ScaleControl(); // 添加比例尺控件
    map.addControl(scaleCtrl);
    const zoomCtrl = new BMap.ZoomControl(); // 添加缩放控件
    map.addControl(zoomCtrl);

    map.disableDoubleClickZoom();

    //保存map对象
    setmap(map);
  }, []);

  //点覆盖物的添加
  useDidUpdateEffect(() => {
    //在这里进行地图打点，并添加数据依赖来刷新界面
    //在打点之前删掉之前所有的点

    const allOverlays = map.getOverlays();
    let plist = [];
    //把所有的点覆盖物的id放入lineid中
    pointlist.forEach((item) => {
      plist.push(item.id);
    });
    //删除所有覆盖物中包含lineid的覆盖物，也就是删除所有的点覆盖物
    allOverlays.forEach((item, index) => {
      if (plist.indexOf(item.id) !== -1) {
        map.removeOverlay(allOverlays[index]);
      }
    });

    pointlist.forEach((item, index) => {
      //   console.log(item);
      const point = new BMap.Point(item.latlng[0], item.latlng[1]);
      //   console.log(item.latlng[0],item.latlng[1])
      const marker = new BMap.Marker(point);
      //给每个点添加一个唯一标识符，作为后续查询操作的标记
      marker.id = item.id;
      //给每个点添加点击复制当前经纬度的功能
      marker.addEventListener("click", function (e) {
        // console.log(e);
        const arrtrans = [];
        arrtrans.push(e.currentTarget.latLng.lng);
        arrtrans.push(e.currentTarget.latLng.lat);
        // console.log(arrtrans)
        const geoc = new BMap.Geocoder();
        let address = "";
        const gdarr = gcoord.transform(arrtrans, gcoord.BD09, gcoord.GCJ02);
        const url =
          "https://uri.amap.com/marker?position=" + gdarr[0] + "," + gdarr[1];
        // copyPageUrl(url, messageApi, "导航链接已复制");

        function getaddress() {
          return new Promise((resolve, reject) => {
            geoc.getLocation(point, (rs) => {
              //   console.log(rs.addressComponents);
              address =
                rs.addressComponents.province +
                rs.addressComponents.city +
                rs.addressComponents.district +
                rs.addressComponents.street +
                rs.addressComponents.streetNumber;
              resolve(address);
            });
            // 这里执行获取数据的操作
            // 如果成功获取数据，调用 resolve 并传递数据
            // 如果获取数据失败，调用 reject 并传递错误信息
          });
        }
        getaddress()
          .then((data) => {
            const content = `
            <style>
                button {
                border: 1px solid;
            }
            </style>
            <div>经纬度：${arrtrans[0]
              .toString()
              .substring(0, 10)},${arrtrans[1].toString().substring(0, 9)}</div>
              <div>地址：${address}</div>
              <div>来源：${item.type}</div>
              <div>${item.bz}</div>
            
            <input id="beizhushuru"/>&nbsp<button id="xiugaibeizhu">修改备注</button>
            <div><button id="getdaohang">复制</button>
            <button id="shanchudian">删除</button></div>
            `;

            const info = new BMap.InfoWindow(content, {
              width: 300,
              height: 160,
              title: "点位操作",
            });
            marker.openInfoWindow(info);
            return info;
          })
          .then((info) => {
            //没有打开情况下，监听打开事件
            if (!info.isOpen()) {
              info.addEventListener("open", function () {
                document.getElementById("getdaohang").onclick = function (e) {
                  copyPageUrl(url, messageApi, "导航链接已复制");
                };

                document.getElementById("shanchudian").onclick = function () {
                  const msg = "确定要删除吗？";

                  if (window.confirm(msg)) {
                    dispatch(
                      deletepointaction({
                        id: e.target.id,
                      })
                    );
                    map.removeOverlay(e.target);
                  }
                };

                document.getElementById("xiugaibeizhu").onclick = function () {
                  const bz = document.getElementById("beizhushuru").value;
                  // console.log(bz)
                  dispatch(
                    updataonpoint({
                      id: e.target.id,
                      remarks: bz,
                    })
                  );
                };
              });
            } else {
              document.getElementById("getdaohang").onclick = function (e) {
                copyPageUrl(url, messageApi, "导航链接已复制");
              };

              document.getElementById("shanchudian").onclick = function () {
                const msg = "确定要删除吗？";

                if (window.confirm(msg)) {
                  dispatch(
                    deletepointaction({
                      id: e.target.id,
                    })
                  );
                  map.removeOverlay(e.target);
                }
              };

              document.getElementById("xiugaibeizhu").onclick = function () {
                const bz = document.getElementById("beizhushuru").value;
                // console.log(bz)
                dispatch(
                  updataonpoint({
                    id: e.target.id,
                    remarks: bz,
                  })
                );
              };
            }
          });

        //获取经纬度后转高德坐标

        // alert('点击位置经纬度：' );
      });

      if (item.latlng.length === 3) {
        const opts = {
          position: point, // 指定文本标注所在的地理位置
          offset: new BMap.Size(-20, -50),
        };
        const label = new BMap.Label(item.latlng[2], opts);
        label.id = item.id;
        marker.setLabel(label);
        // map.addOverlay(label);
      }
      //地图打点的地方
      map.addOverlay(marker);

        if (index === pointlist.length - 1) {
    //   if (index === 1) {
        map.centerAndZoom(point);
      }
    });
    //  console.log("打点")
  }, [pointlist]);

  //线覆盖物的添加
  useDidUpdateEffect(() => {
    //拿到所有的覆盖物
    const allOverlays = map.getOverlays();
    let lineid = [];
    //把所有的线覆盖物的id放入lineid中
    showlinelist.forEach((item) => {
      lineid.push(item.id);
    });
    //删除所有覆盖物中包含lineid的覆盖物，也就是删除所有的线覆盖物
    allOverlays.forEach((item, index) => {
      if (lineid.indexOf(item.id) !== -1) {
        map.removeOverlay(allOverlays[index]);
      }
    });
    // map.removeOverlay();
    // console.log("redux中的", showlinelist);
    //拿到redux中的所有数据
    showlinelist.forEach((item, index) => {
      //拿到每个线的点位数据
      const arr = item.point;
      let pointlist = [];
      arr.forEach((item) => {
        pointlist.push(new BMap.Point(item[1], item[0]));
      });
      //创建文本标注
      let textdis = [];
      let sum = 0;
      let distance = 0;
      pointlist.forEach((item1, index) => {
        if (index !== 0) {
          distance = Math.round(map.getDistance(pointlist[index - 1], item1));
          sum += distance;

          textdis.push({
            point: item1,
            distance: distance,
          });
          const opts = {
            position: item1, // 指定文本标注所在的地理位置
            offset: new BMap.Size(-20, -30),
          };
          let content = Math.round(
            map.getDistance(pointlist[index - 1], item1)
          );
          const label = new BMap.Label(content + "m", opts);
          //此处添加和折线一样的id，就可以在之前根据折线删除历史折线的时候删掉
          label.id = item.id;
          map.addOverlay(label);
        }
      });

      //在画线结束之后在最后面添加线的总长度
      if (
        item.isover === true &&
        item.point.length !== 1 &&
        item.point.length > 2
      ) {
        const lat = item.point[item.point.length - 1][1];
        const lng = item.point[item.point.length - 1][0];
        const point = new BMap.Point(lat, lng);
        const opts = {
          position: point, // 指定文本标注所在的地理位置

          offset: new BMap.Size(-20, -30),
        };

        const label = new BMap.Label("总长度" + sum + "m", opts);
        //此处添加和折线一样的id，就可以在之前根据折线删除历史折线的时候删掉
        label.id = item.id;
        map.addOverlay(label);
      }

      //   console.log(textdis)

      //创建折线
      const polyline = new BMap.Polyline(pointlist, {
        strokeColor: "blue",
        strokeWeight: 3,
        strokeOpacity: 0.5,
        enableClicking: true,
      });
      //给每个折线添加自己的id
      polyline.id = item.id;
      polyline.isEditing = false;
      //添加线位数据
      map.addOverlay(polyline);

      //设置双击删除线
      function dblclick(e) {
        const msg = "确定删除吗？";
        if (window.confirm(msg)) {
          dispatch(dellineaction({ id: e.target.id }));
          const allOverlays = map.getOverlays();
          const id = e.target.id;
          allOverlays.forEach((item, index) => {
            if (item.id === id) {
              map.removeOverlay(allOverlays[index]);
            }
          });
        }
      }

      //开启线的编辑，并添加点击地图取消编辑的功能
      function linec() {
        polyline.enableEditing();
        polyline.isEditing = true;
        map.addEventListener("click", dellinec);
      }

      //取消编辑点位数据，并删除地图删除的事件
      function dellinec() {
        polyline.disableEditing();
        polyline.isEditing = false;
        map.removeEventListener("click", dellinec);
      }

      //当线被编辑的时候，动态修改redux中的数据
      function onlineupdate(e) {
        if (polyline.isEditing) {
          //此处调用移除监听事件用于解决地图上更改数据引起redux更新，redux更新又引起地图重新渲染，重新渲染又会触发lineupdate事件，所以在这个情况下删除前一次的lineupdate事件，
          //因为数据在重新渲染时会把之前地图上存在的折线数据全部删除，包括事件，此时就不会引起死循环。
          polyline.removeEventListener("lineupdate", onlineupdate);
          // 用于遍历存放折线中的所有数据
          let arr = [];
          const id = e.currentTarget.id;
          const pointarr = e.currentTarget.getPath();
          console.log(e);
          // console.log(pointarr)
          pointarr.forEach((item) => {
            arr.push([item.lat, item.lng]);
          });
          // console.log(arr);
          dispatch(
            lineedit({
              id,
              point: arr,
              isover: true,
            })
          );
        }
      }
      //这个判断用于解决第一次画线的时候会执行更新完线的代码的问题
      if (item.isover === true) {
        // console.log(item.id,item.isover)
        // console.log("画完线了")
        polyline.addEventListener("lineupdate", onlineupdate);
      }

      polyline.addEventListener("click", linec);

      polyline.addEventListener("dblclick", dblclick);
    });
  }, [showlinelist]);

  //多边形覆盖物的添加
  useDidUpdateEffect(() => {
    //拿到所有的覆盖物
    const allOverlays = map.getOverlays();
    let lineid = [];
    //把所有的多边形覆盖物的id放入lineid中
    showgonlist.forEach((item) => {
      lineid.push(item.id);
    });
    //删除所有覆盖物中包含lineid的覆盖物，也就是删除所有的多边形覆盖物
    allOverlays.forEach((item, index) => {
      if (lineid.indexOf(item.id) !== -1) {
        map.removeOverlay(allOverlays[index]);
      }
    });
    // map.removeOverlay();
    // console.log("redux中的", showlinelist);
    //拿到redux中的所有数据
    showgonlist.forEach((item, index) => {
      //拿到每个多边形的点位数据
      const arr = item.point;
      let pointlist = [];
      arr.forEach((item) => {
        pointlist.push(new BMap.Point(item[1], item[0]));
        // console.log(item[1], item[0])
      });

      const Polygon = new BMap.Polygon(pointlist, {
        strokeColor: "blue",
        strokeWeight: 3,
        strokeOpacity: 0.5,
        enableClicking: true,
      });
      //给每个多边形添加自己的id
      Polygon.id = item.id;
      Polygon.isEditing = false;
      //添加多边形数据
      map.addOverlay(Polygon);

      //   console.log(index,showgonlist.length)
      if (index === showgonlist.length - 1) {
        // console.log(item.point[0])
        // console.log(showgonlist[index].point[0][0], showgonlist[index].point[0][1])

        const pointcen = new BMap.Point(
          showgonlist[index].point[0][1],
          showgonlist[index].point[0][0]
        );
        // map.centerAndZoom(pointcen);
      }

      //设置双击删除多边形
      function dblclick(e) {
        const msg = "确定删除吗？";
        if (window.confirm(msg)) {
          dispatch(delpolygonaction({ id: e.target.id }));
          const allOverlays = map.getOverlays();
          const id = e.target.id;
          allOverlays.forEach((item, index) => {
            if (item.id === id) {
              map.removeOverlay(allOverlays[index]);
            }
          });
        }
      }

      function onlineupdate(e) {
        if (Polygon.isEditing) {
          //此处调用移除监听事件用于解决地图上更改数据引起redux更新，redux更新又引起地图重新渲染，重新渲染又会触发lineupdate事件，所以在这个情况下删除前一次的lineupdate事件，
          //因为数据在重新渲染时会把之前地图上存在的折线数据全部删除，包括事件，此时就不会引起死循环。
          Polygon.removeEventListener("lineupdate", onlineupdate);
          // 用于遍历存放多边形中的所有数据
          let arr = [];
          const id = e.currentTarget.id;
          const pointarr = e.currentTarget.getPath();
          // console.log(id)
          // console.log(pointarr)
          pointarr.forEach((item) => {
            arr.push([item.lat, item.lng]);
          });
          // console.log(arr);
          dispatch(
            polygonedit({
              id,
              point: arr,
              isover: true,
            })
          );
        }
      }
      //这个判断用于解决第一次画线的时候会执行更新完线的代码的问题
      if (item.isover === true) {
        // console.log("执行更新一次")
        Polygon.addEventListener("lineupdate", onlineupdate);
      }

      //开启线的编辑，并添加点击地图取消编辑的功能
      function linec() {
        Polygon.enableEditing();
        Polygon.isEditing = true;
        map.addEventListener("click", dellinec);
      }
      //取消编辑点位数据，并删除地图删除的事件
      function dellinec() {
        Polygon.disableEditing();
        Polygon.isEditing = false;
        map.removeEventListener("click", dellinec);
      }

      Polygon.addEventListener("click", linec);

      Polygon.addEventListener("dblclick", dblclick);
    });
  }, [showgonlist]);

  //圆覆盖物的添加
  useDidUpdateEffect(() => {
    //拿到所有的覆盖物
    const allOverlays = map.getOverlays();
    let lineid = [];
    //把所有的圆覆盖物的id放入lineid中
    showcircle.forEach((item) => {
      lineid.push(item.id);
    });
    //删除所有覆盖物中包含lineid的覆盖物，也就是删除所有的多边形覆盖物
    allOverlays.forEach((item, index) => {
      if (lineid.indexOf(item.id) !== -1) {
        map.removeOverlay(allOverlays[index]);
      }
    });

    showcircle.forEach((item) => {
      //拿到每个圆的点位数据
      const point = item.point;
      const distance = item.distance;
      //   const endp = item.endclickp;
      const p = new BMap.Point(point[1], point[0]); //中心点

      const opts = {
        position: p, // 指定文本标注所在的地理位置
        offset: new BMap.Size(-20, -30),
      };

      const label = new BMap.Label(Math.round(distance) + "m", opts);
      //此处添加和折线一样的id，就可以在之前根据折线删除历史折线的时候删掉
      label.id = item.id;
      map.addOverlay(label);

      //创建折线
      const Circle = new BMap.Circle(p, distance, {
        strokeColor: "blue",
        strokeWeight: 3,
        strokeOpacity: 0.5,
        enableClicking: true,
      });
      //给每个多边形添加自己的id
      Circle.id = item.id;
      //添加多边形数据
      map.addOverlay(Circle);

      //设置双击删除多边形
      function dblclick(e) {
        const msg = "确定删除吗？";
        if (window.confirm(msg)) {
          dispatch(delcircleaction({ id: e.target.id }));
          const allOverlays = map.getOverlays();
          const id = e.target.id;
          allOverlays.forEach((item, index) => {
            if (item.id === id) {
              map.removeOverlay(allOverlays[index]);
            }
          });
        }
      }

      //二次编辑引起的数据更新

      function oncircleupdate(e) {
        // console.log("更新这个圆的数据地图")
        //此处调用移除监听事件用于解决地图上更改数据引起redux更新，redux更新又引起地图重新渲染，重新渲染又会触发lineupdate事件，所以在这个情况下删除前一次的lineupdate事件，
        //因为数据在重新渲染时会把之前地图上存在的折线数据全部删除，包括事件，此时就不会引起死循环。
        Circle.removeEventListener("click", oncircleupdate);
        // 用于遍历存放圆形中的所有数据
        const id = e.currentTarget.id;
        const point = e.currentTarget.getCenter();
        // console.log(point)
        const distance = e.currentTarget.getRadius();

        dispatch(
          circleedit({
            id,
            point: [point.lat, point.lng],
            distance: distance,
          })
        );
      }
      //这个判断用于解决第一次画线的时候会执行更新完线的代码的问题
      //   console.log(item.isover)

      //开启圆的编辑，并添加点击地图取消编辑的功能
      function linec() {
        // console.log("开启编辑")
        Circle.enableEditing();
        map.addEventListener("click", dellinec);
        if (item.isover === true) {
          // console.log("执行更新一次")
          // console.log("添加事件")
          Circle.addEventListener("click", oncircleupdate);
        }
      }
      //取消编辑点位数据，并删除地图删除的事件
      function dellinec() {
        // console.log("结束编辑")
        Circle.disableEditing();
        map.removeEventListener("click", dellinec);
      }

      Circle.addEventListener("click", linec);

      Circle.addEventListener("dblclick", dblclick);
    });
  }, [showcircle]);

  const addonep = (onepoint, BMap, map, copyPageUrl) => {
    const point = new BMap.Point(onepoint[0], onepoint[1]);
    console.log(onepoint[0], onepoint[1]);
    const marker = new BMap.Marker(point);

    marker.addEventListener("click", function (e) {
      // console.log(e);
      const arrtrans = [];
      arrtrans.push(e.currentTarget.latLng.lng);
      arrtrans.push(e.currentTarget.latLng.lat);
      // console.log(arrtrans)
      //获取经纬度后转高德坐标
      const gdarr = gcoord.transform(arrtrans, gcoord.BD09, gcoord.GCJ02);
      const url =
        "https://uri.amap.com/marker?position=" + gdarr[0] + "," + gdarr[1];
      copyPageUrl(url, messageApi, "导航链接已复制");

      // alert('点击位置经纬度：' );
    });

    marker.addEventListener("");

    map.addOverlay(marker);
    if (onepoint.length === 3) {
      const opts = {
        position: point, // 指定文本标注所在的地理位置
        offset: new BMap.Size(-20, -50),
      };
      const label = new BMap.Label(onepoint[2], opts);
      map.addOverlay(label);
    }
    // map.centerAndZoom(point, 16);
  };

  return (
    <Mainwrap>
      {daohangHolder}
      <div>
        {showplist_all && <Listdata addonep={addonep} map={map} />}
        {/* <Button type="primary" className="searchadd">添加到地图</Button> */}
      </div>
      <div ref={mapref} id="bmap" style={{ height: "95vh", zIndex: "0" }}></div>

      {/* <Mapdraw map={map}/> */}
    </Mainwrap>
  );
});

export default Mainmap;
