import styled from "styled-components";

export const Mainwrap = styled.div`
  display: flex;
  width: 800px;
  .maindiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    .top {
    }
    .body {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .left {
        width: 320px;
        margin-right: 50px;
      }
      .right {
        
        width: 450px;
      }
    }
    .foot {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 10px;

      .submitbutton {
        width: 60px;
        margin-right: 80px;
      }
      .showbutton{
        width: 90px;
      }
    }
  }
`;
