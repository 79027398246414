import { createSlice } from "@reduxjs/toolkit";
export const circleSlice = createSlice({
  name: "circleSlice",
  initialState: {
    showcircle: [],
  },
  reducers: {
    //右击之后完成画线
    dellasttwo(state, action) {
        state.showcircle.forEach((item,index) => {
            if (item.id === action.payload.id) {
                //删除最后的一个点
               state.showcircle[index].point.pop()
               
            }
          });
      // console.log(action.payload.mappointlist)
    },
    //点击之后添加一个折线点位
    addoneaction(state, action) {

        state.showcircle.push({
          id: action.payload.id,
          point: action.payload.onepoint,
          endclickp:[],
          distance:0,
          
        });
       
    //    console.log("state中click的值",state.clicknum)
      // console.log(action.payload.mappointlist)
    },
    //配置移动变化折线
    updataminaction(state, action) {
      state.showcircle.forEach((item, index) => {
        //寻找需要改变的数据
        if (item.id === action.payload.id) {
            //将圆形的长度进行动态修改，起到动态的效果
         state.showcircle[index].distance=action.payload.distance
         state.showcircle[index].endclickp = action.payload.endclickp
         
        }
      });

      // console.log(action.payload.mappointlist)
    },
    delcircleaction(state, action){
        state.showcircle.forEach((item,index)=>{
            if(item.id===action.payload.id){
                state.showcircle.splice(index,1)
            }
        })
    },
    isdrawover(state,action){
        state.showcircle.forEach((item,index)=>{
            if(item.id===action.payload.id){
                state.showcircle[index].isover=true
            }
        })
    },
    circleedit(state,action){
        // console.log(action.payload)
        state.showcircle.forEach((item,index)=>{
            
            if(item.id===action.payload.id&&state.showcircle[index].isover===true){
                state.showcircle[index].distance=action.payload.distance
                state.showcircle[index].point=action.payload.point
            }
        })
    },
    savecircleaction(state,action){
        // 保存所有的数据
        // console.log(action.payload.circle)
       state.showcircle=action.payload.circle.showcircle
    },
    //添加基站查询添加的圆圈
    addlacaction(state,action){
        // console.log(action.payload)
        state.showcircle.forEach((item,index)=>{
            // if(item?.type==="yuan"){
            //     state.showcircle.splice(index, 1);
            // }
        })




        action.payload.arr.forEach(element => {
            // console.log(element.coord.map(text => Number(text.replace(/"/g, ''))))
          
            state.showcircle.push({
                id: element.id,
                point: element.coord.map(text => Number(text.replace(/"/g, ''))).reverse(),
                endclickp:[],
                distance:Number(element.radius.replace(/"/g, '')),
                isover:true,
                type:element?.type
              });
        }); 
    }
  },
});

export const { dellasttwo, addoneaction, updataminaction,delcircleaction,circleedit,isdrawover,savecircleaction,addlacaction } =
circleSlice.actions;
export default circleSlice.reducer;
