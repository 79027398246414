const devBaseURL = "https://mapapi.0577bit.com:6012/";
const proBaseURL = "https://mapapi.0577bit.com:6012/";
export const BASE_URL = process.env.NODE_ENV === 'development' ? devBaseURL: proBaseURL;

export const TIMEOUT = 5000;

export const xcxURL="wss://xcxapi.0577bit.com:7778/api"
export const xcxapi="https://xcxapi.0577bit.com:7778/api"


// export const xcxURL="ws://localhost:7778/api"
// export const xcxapi="http://localhost:7778/api"

// export const xcxURL="ws://localhost:7778/api"
// export const xcxapi="http://192.168.66.110:7778/api"