import styled from "styled-components";

export const Mainwrap = styled.div`
  .tishi {
    font-size: larger;
  }
  .workspace {
    height: 600px;
  }
  .yzm {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    .yzm-title {
      font-size: x-large;
      font-weight: 600;
      text-align: center;
    }
    .yzm-num {
      font-size: xx-large;
      letter-spacing: 10px;
    }
    .qrcode {
      width: 150px;
      height: 150px;
      /* align-items: center;
      justify-content:center; */
    }
  }
`;
