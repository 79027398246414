import { v4 as uuidv4 } from "uuid";
import { updataminaction, addoneaction,dellasttwo } from "../../../../store/lineSlice";

export const linedraw = (map, dispatch,setaddone) => {
  // const { showlinelist } = useSelector((store) => store.line);
  const BMap = window.BMapGL;
  //创建一个当前点位的id
  let id = uuidv4();

    function click(e){
        const { lat, lng } = e.latlng;
        const point = [lat, lng];
    
        dispatch(
          addoneaction({
            onepoint: point,
            id: id,
          })
        );
    }
    function mousemove(e){
        const { lat, lng } = e.latlng;
        dispatch(
          updataminaction({
            lastpoint: [lat, lng],
            id: id,
          })
        );
    }

    function rightclick(e){
        map.removeEventListener("click", click);
        map.removeEventListener("mousemove", mousemove);
        map.removeEventListener("rightclick", rightclick);
        setaddone("auto")

        dispatch(dellasttwo({
            id:id
        }))
        //完成之后开启可用
    }


  map.addEventListener("click", click);

  map.addEventListener("rightclick",rightclick);

  map.addEventListener("mousemove", mousemove);
};
