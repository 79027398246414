import React, { memo, useRef } from "react";
import geohash from "ngeohash";
import gcoord from "gcoord";
import geohashf from "../../../../src/img/geohash.xls"
import { Mainwrap } from "./style";
import * as XLSX from "xlsx";
import { v4 as uuidv4 } from "uuid";
import { InboxOutlined } from "@ant-design/icons";
import { Upload, Card, Input, Radio, Button, message, Popover } from "antd";
import { geohashpolygonaction } from "../../../store/polygonSlice";
import {
  addonepointaction,
  changeshowplist_all,
} from "../../../store/mappointSlice";
import { useDispatch } from "react-redux";

const { Dragger } = Upload;
const { TextArea } = Input;

const Geohash = memo((props) => {
  const [messageApi, contextHolder] = message.useMessage();

  //    const a =  geohash.decode_bbox("ww8p1r")
  // console.log(a)
  const { setgeohashshowFalse } = props;
  const dispatch = useDispatch();
  const inputref = useRef("");
  const geohashclick = () => {
    // console.log(inputref.current.input.value)
    let inputvalue = inputref.current.resizableTextArea.textArea.value;
    //未输入数据的情况下直接返回并提示
    if (inputvalue == "") {
      messageApi.open({
        type: "info",
        content: "未输入数据",
        duration: 3,
      });
      return;
    }
    //对字符串作切割
    let reg = new RegExp("，", "g");
    inputvalue = inputvalue.trim().replace(reg, ",");
    const inputarr = inputvalue.split("\n");
    //console.log(inputarr)
    //遍历数据之后再作切割
    // inputarr.forEach((item) => {
    //   item = item.split(",");
    //   const arr = geohash.decode_bbox(item[0]);

    //   //console.log(item)
    //   const lacp = (arr[1] + arr[3]) / 2 + "";
    //   const lonp = (arr[0] + arr[2]) / 2 + "";
    //   const centerp = gcoord.transform([lacp, lonp], gcoord.WGS84, gcoord.BD09);
    //   let arr2 = [
    //     [arr[1], arr[0]],
    //     [arr[3], arr[2]],
    //   ];
    //   let arr3 = [];
    //   arr2.forEach((element) => {
    //     arr3.push(gcoord.transform(element, gcoord.WGS84, gcoord.BD09));
    //   });
    //   // console.log(arr3)
    //   //创建一个数组
    //   let arradd = [];
    //   arradd.push(
    //     [arr3[0][1], arr3[0][0]],
    //     [arr3[0][1], arr3[1][0]],
    //     [arr3[1][1], arr3[1][0]],
    //     [arr3[1][1], arr3[0][0]]
    //   );
    //   // let bdarr =[]
    //   // arradd.forEach(item => {
    //   //     console.log(item)
    //   //     bdarr.push(gcoord.transform(item,gcoord.WGS84,gcoord.BD09))
    //   // });
    //   // console.log(bdarr);
    //   const obj = {
    //     id: uuidv4(),
    //     point: arradd,
    //     isover: true,
    //   };
    //   let bz = "";
    //   let onepoint = {
    //     latlng: centerp,
    //     id: uuidv4(),
    //     type: "geohash添加",
    //     bz: bz,
    //   };
    //   if (item.length === 2) {
    //     centerp.push(item[1]);
    //   }
    //   dispatch(geohashpolygonaction(obj));
    //   dispatch(
    //     addonepointaction({
    //       onepoint: onepoint,
    //     })
    //   );
    // });
    addp(inputarr);
    dispatch(changeshowplist_all());
    setgeohashshowFalse();
  };
  //两个打点相同的业务代码
  const addp = (arr) => {
    arr.forEach((item) => {
      item = item.split(",");
      const arr = geohash.decode_bbox(item[0]);

      //console.log(item)
      const lacp = (arr[1] + arr[3]) / 2 + "";
      const lonp = (arr[0] + arr[2]) / 2 + "";
      const centerp = [lacp, lonp]
    //   console.log(centerp)
      let arr2 = [
        [arr[1], arr[0]],
        [arr[3], arr[2]],
      ];
    //   let arr3 = [];
    //   arr2.forEach((element) => {
    //     arr3.push(gcoord.transform(element, gcoord.WGS84, gcoord.BD09));
    //   });
      // console.log(arr3)
      //创建一个数组
      let arradd = [];
      arradd.push(
        [arr2[0][1], arr2[0][0]],
        [arr2[0][1], arr2[1][0]],
        [arr2[1][1], arr2[1][0]],
        [arr2[1][1], arr2[0][0]]
      );
      // let bdarr =[]
      // arradd.forEach(item => {
      //     console.log(item)
      //     bdarr.push(gcoord.transform(item,gcoord.WGS84,gcoord.BD09))
      // });
      // console.log(bdarr);
      const obj = {
        id: uuidv4(),
        point: arradd,
        isover: true,
      };
      let bz = "";
      let onepoint = {
        latlng: centerp,
        id: uuidv4(),
        type: "geohash添加",
        bz: bz,
      };
      if (item.length === 2) {
        centerp.push(item[1]);
      }
      dispatch(geohashpolygonaction(obj));
      dispatch(
        addonepointaction({
          onepoint: onepoint,
        })
      );
    });
  };
  const drprops = {
    name: "excelfile",
    accept: ".xls , .xlsx",
    maxCount: 1,
    multiple: true,
    //解析上传的excel
    beforeUpload: (file, fileList) => {
      var rABS = true;
      const f = fileList[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        if (!rABS) data = new Uint8Array(data);
        var workbook = XLSX.read(data, {
          type: rABS ? "binary" : "array",
        });
        // 假设我们的数据在第一个标签
        var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
        // XLSX自带了一个工具把导入的数据转成json
        var jsonArr = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });
        jsonArr.splice(0, 1);
        // console.log(jsonArr);
        const jsonArrjoin = [];
        jsonArr.forEach((item) => {
          jsonArrjoin.push(item.join(","));
        });
        console.log(jsonArrjoin);

        addp(jsonArrjoin);
        dispatch(changeshowplist_all());
        setgeohashshowFalse();
        // dispatch(changepointdata({ mappointlist: changearr }));
        // dispatch(changeshowplist_all());
        // dispatch(openshowplist());
      };
      if (rABS) reader.readAsBinaryString(f);
      else reader.readAsArrayBuffer(f);
      return false;
    },
  };

  return (
    // <Mainwrap>
    //   <Input placeholder="此处输入geohash" ref={inputref} className="input" />
    //   <Button type="primary" onClick={geohashclick}>
    //     查询
    //   </Button>
    // </Mainwrap>

    <Mainwrap>
      {contextHolder}

      <div className="inputwrap">
        {/* 上传框 */}
        <a href={geohashf}>下载模板</a>
        <div className="upload">
          <Dragger {...drprops}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-hint">支持拖拽和点击上传excel</p>
          </Dragger>
        </div>
        {/* 示例参考格式 */}

        {/* 批量录入输入框 */}
        <p>批量录入</p>
        <TextArea
          rows={9}
          ref={inputref}
          placeholder="请输入GEOHASH，并使用回车分割，例如:
W5GH52
W5GH53
---支持添加备注，每行数据最后输入备注，以逗号分隔，例如：
W5GH52，落脚点
建议输入备注加以区分
"
        />

        <Button type="primary" block onClick={geohashclick}>
          打点
        </Button>
      </div>
    </Mainwrap>
  );
});

export default Geohash;
