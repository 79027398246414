import "./App.css";
import Mainmap from "./pages/mainmap";
import { Provider } from "react-redux";
import { BrowserRouter, useRoutes } from "react-router-dom";
import store from "./store";
import routes from "./router";
import Headerfile from "./pages/headerfile";
import Footerba from "./pages/btnapp/footer";
import Userface from "./pages/userface";

import "moment/dist/locale/zh-cn";
import "moment-lunar";
// import biticon from "./img/bit.png"

function App() {
  function RouteElement() {
    const element = useRoutes(routes);
    return element;
  }

  return (
    <Provider store={store}>
      {/* <Headerfile/>
      <div className="App">
        <Mainmap />
      </div>
      <Footerba/> */}
      <BrowserRouter>
        <RouteElement />
      </BrowserRouter>
      {/* <Userface/> */}
    </Provider>
  );
}

export default App;
