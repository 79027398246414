import styled from "styled-components";

export const Mainwrap = styled.div`
  display: flex;
  flex-direction: column;
  .select {
    flex: 1;
    width: 125px;
  }
  .divclass{
    flex: 50;
    display: flex;
  }



`;
