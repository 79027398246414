import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
export const mappointSlice = createSlice({
  name: "mappointSlice",
  initialState: {
    pointlist: [], //所有打点数据存放的数组
    pointone: [], //单个打点数据存放的数组
    pointmany: [], //批量打点数据存放的数组
    pointjizhan: [], //基站打点数据存放的数组
    originalpoint: "", //最初输入的坐标数据，不作改动
    shareurl: "烽哥牛逼",
    showplist: false,
    showplist_all: false,
    pengzhuang: {},
  },
  reducers: {
    //地图批量打点功能
    changepointdata(state, action) {
      let arraddid = [];
      // console.log("批量打点"+action.payload.mappointlist)
      action.payload.mappointlist.forEach((item) => {
        // console.log(item)
        arraddid.push({
          latlng: [...item],
          id: uuidv4(),
          type: "批量添加",
          bz: "",
        });
      });
      state.pointmany = arraddid;
      state.pointlist = state.pointmany
        .concat(state.pointone)
        .concat(state.pointjizhan);
    },
    //对查询出来的ip地址进行批量上图
    ippointdata(state, action) {
      let arraddid = [];
      // console.log("批量打点"+action.payload.mappointlist)
      action.payload.mappointlist.forEach((item) => {
        if (item.lng !== "") {
          arraddid.push({
            latlng: [parseFloat(item.lng), parseFloat(item.lat),item.ip],
            id: uuidv4(),
            type: "ip查询",
            bz: item.ip,
          });
          console.log(item.ip)
        }
      });
      state.pointmany = state.pointmany.concat(arraddid);
      state.pointlist = state.pointmany
        .concat(state.pointone)
        .concat(state.pointjizhan);
        state.showplist_all = true;
        state.showplist = true;
    },
    //轨迹碰撞
    pengzhuangpointdata(state, action) {
      let arraddid = [];
      // console.log("批量打点"+action.payload.mappointlist)
      action.payload.mappointlist.forEach((item) => {
        arraddid.push({
          latlng: [...item],
          id: uuidv4(),
          type: "轨迹碰撞",
          bz: "",
        });
      });
      state.pointmany = arraddid;
      state.pointlist = state.pointmany
        .concat(state.pointone)
        .concat(state.pointjizhan);
    },
    //基站添加数据的代码
    changepointdatajizhan(state, action) {
      let arraddid = [];
      // console.log(action.payload.mappointlist)
      action.payload.mappointlist.forEach((item) => {
        arraddid.push({
          latlng: item.arr,
          id: uuidv4(),
          type: "基站查询",
          bz: "LA:" + item.bz,
        });
      });
      state.pointjizhan = arraddid;
      state.pointlist = state.pointjizhan
        .concat(state.pointone)
        .concat(state.pointmany);
    },
    //使用取点功能添加单个打点
    addonepointaction(state, action) {
      //   console.log(action)
      state.pointone.push(action.payload.onepoint);
      state.pointlist = state.pointjizhan
        .concat(state.pointmany)
        .concat(state.pointone);
      //   console.log(state.pointlist)
    },
    //更新单个打点数据
    updataonpoint(state, action) {
      //    console.log(action)
      state.pointlist = state.pointjizhan
        .concat(state.pointmany)
        .concat(state.pointone);
      state.pointlist.forEach((item, index) => {
        if (item.id === action.payload.id) {
          console.log(action.payload.id, typeof action.payload.remarks);
          state.pointlist[index].latlng[2] =
            // ...state.pointlist[index].latlng,
            action.payload.remarks;

          //   state.pointlist[index].type = action.payload.type
          //   state.pointlist[index].bz=action.payload.bz
          //   console.log(state.pointlist[index]);
        }
      });

      // console.log(action.payload.mappointlist)
    },
    //删除单个点位
    deletepointaction(state, action) {
      state.pointlist.forEach((item, index) => {
        if (item.id === action.payload.id) {
          // console.log("匹配到了1")
          state.pointlist.splice(index, 1);
        }
      });
      state.pointone.forEach((item, index) => {
        if (item.id === action.payload.id) {
          // console.log("匹配到了2")
          state.pointone.splice(index, 1);
        }
      });
      state.pointmany.forEach((item, index) => {
        if (item.id === action.payload.id) {
          // console.log("匹配到了3")
          state.pointmany.splice(index, 1);
        }
      });
    },
    //保存分享连接
    changeshareurl(state, action) {
      state.shareurl = action.payload.url;
    },
    //是否展示列表
    changeshowplist(state, action) {
      state.showplist = !state.showplist;
    },
    //是否出现列表
    changeshowplist_all(state, action) {
      state.showplist_all = true;
    },
    //打开列表
    openshowplist(state, action) {
      state.showplist = true;
    },
    savepointaction(state, action) {
      // 保存所有的数据

      state.pointlist = action.payload.point.pointlist;
      state.pointone = action.payload.point.pointone;
      state.pointmany = action.payload.point.pointmany;
      state.pointjizhan = action.payload.point.pointjizhan;
      state.originalpoint = action.payload.point.originalpoint;
      state.shareurl = action.payload.point.shareurl;
      state.showplist = action.payload.point.showplist;
      state.showplist_all = action.payload.point.showplist_all;
    },
    //显示所有的需要显示的point
    showprojectpoint(state, action) {
      state.pointlist = action.payload.point;
      state.pointmany = action.payload.point;
      state.showplist_all = true;
      state.showplist = true;
    },
    //保存最初输入的数据
    originalpointsave(state, action) {
      state.originalpoint = action.payload.point;
    },
  },
});

export const {
  changepointdata,
  changeshareurl,
  changeshowplist,
  changeshowplist_all,
  openshowplist,
  addonepointaction,
  updataonpoint,
  deletepointaction,
  savepointaction,
  changepointdatajizhan,
  showprojectpoint,
  pengzhuangpointdata,
  originalpointsave,
  ippointdata,
} = mappointSlice.actions;
export default mappointSlice.reducer;
