import React, { memo, useRef, useState } from "react";
import { Mainwrap } from "./style";
import { Button, Input ,message} from "antd";
import axios from "axios";
import { useDispatch } from "react-redux";
import { xcxapi } from "../../../services/config";
import {
  ippointdata,
} from "../../../store/mappointSlice";

const IPsearch = memo(() => {
  const { TextArea } = Input;
  const ipref = useRef();
  const ipresref = useRef();
  const [resuip, setresuip] = useState("");
  const [ipjwdaddress,setipjwdaddress]=useState([])
  const placeholder = "支持批量输入,每行一条数据,最多一次性返回100条查询结果";
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  //批量查询IP
  const sendipdata = async () => {
    const ipdata = ipref.current.resizableTextArea.textArea.value;
    const res = await axios.post(`${xcxapi}/application/getmoreipaddress`, {
      ipstring: ipdata,
    });
    const ipsearchres = res.data.data;
    setipjwdaddress(ipsearchres)
    console.log(ipsearchres);
    let str = "";
    ipsearchres.forEach((item) => {
      str = str + item.ip + " " + item.address + "\n";
    });
    setresuip(str);
    messageApi.success(`查询成功：共计${ipsearchres.length}条数据`);
  };
  //显示ip地址到地图上
  const showipaddress = () => {
    dispatch(ippointdata({ mappointlist: ipjwdaddress }));
    messageApi.success(`已显示至地图，部分未显示因ip地址无经纬度数据`);
  };
  return (
    <Mainwrap>
      {contextHolder}
      <div className="maindiv">
        <div className="top"></div>
        <div className="body">
          <div className="left">
            <TextArea rows={25} placeholder={placeholder} ref={ipref} />
          </div>
          <div className="right">
            <TextArea
              rows={25}
              placeholder="查询结果会显示在这里"
              ref={ipresref}
              value={resuip}
            />
          </div>
        </div>
        <div className="foot">
          <Button type="primary" className="submitbutton" onClick={sendipdata}>
            查询
          </Button>
          <Button type="primary" className="showbutton" onClick={showipaddress} disabled={ipjwdaddress.length===0}>
            结果上图
          </Button>
        </div>
      </div>
    </Mainwrap>
  );
});

export default IPsearch;
