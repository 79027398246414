import { v4 as uuidv4 } from "uuid";
import { updataonpoint, addonepointaction,changeshowplist_all } from "../../../store/mappointSlice";




export const addonepointf=(map,dispatch,setaddone,BMap)=>{
    
    function addonemarker(type) {
      const { lat, lng } = type.latlng;
      // console.log(type)
      const id = uuidv4();
      const obj = {
        type:"点击拾取",
        bz:"",
        id: id,
        latlng: [lng.toString(), lat.toString()],
      };
    //   console.log("本次随机的id为",id)
      //通过dispatch派发添加点位
    //   debugger;
      dispatch(addonepointaction({ onepoint: obj }));
    //   debugger;
      dispatch(changeshowplist_all())
      map.removeEventListener("click", addonemarker);
      //添加完毕之后启用点击
      setaddone("auto");
      
      

      //打开一个交互信息窗口
      
      setTimeout(()=>{
       
      
      const allOverlays = map.getOverlays();
    //   console.log(allOverlays.length)
      allOverlays.forEach(item => {
            // console.log("所有的id",item.id)
        if(item.id===id){
            // console.log("匹配成功")
            const content = `
        <div><input id="remarks"></input><button id="addbtnbz">添加</button></div>
        `;
        // const point = new BMap.Point(lng, lat);
        const info = new BMap.InfoWindow(content, {
          width: 0,
          height: 0,
          title: "添加备注",
        });
        // debugger;
        item.openInfoWindow(info)

        }
      });

      setTimeout(() => {
        // debugger;
        const btn = document.getElementById("addbtnbz")
        btn.onclick = function (e) {
            const remarks = document.getElementById("remarks").value;
            //循环遍历所有的点位数据，找到数据在最后添加备注
            const allOverlays = map.getOverlays();
          //   console.log(allOverlays)
            for (let i = 0; i < allOverlays.length; i++) {
              if (allOverlays[i].id && allOverlays[i].id == id) {
                dispatch(
                  updataonpoint({
                    remarks: remarks,
                    id: id,
                  })
                );
              }
            }
            
          //   console.log(remarks);
          };
    },500)
        }, 1000);
      
      //为按钮添加点击事件
    }
    map.addEventListener("click", addonemarker);
}





