import { createSlice } from "@reduxjs/toolkit";
// import { v4 as uuidv4 } from "uuid";
export const polygonSlice = createSlice({
  name: "polygonSlice",
  initialState: {
    showgonlist: [],
  },
  reducers: {
    //右击之后完成画线
    dellasttwo(state, action) {
      state.showgonlist.forEach((item, index) => {
        if (item.id === action.payload.id) {
          state.showgonlist[index].point.pop();
          state.showgonlist[index].isover = true;
        }
      });
      // console.log(action.payload.mappointlist)
    },
    //点击之后添加一个折线点位
    addoneaction(state, action) {
      let i = 0;
      state.showgonlist.forEach((item, index) => {
        if (item.id === action.payload.id) {
          state.showgonlist[index].point.push(action.payload.onepoint);
          i = i + 1;
        }
      });

      if (i === 0) {
        state.showgonlist.push({
          id: action.payload.id,
          point: [action.payload.onepoint],
        });
      }

      // console.log(action.payload.mappointlist)
    },
    //配置移动变化多边形
    updataminaction(state, action) {
      state.showgonlist.forEach((item, index) => {
        //寻找需要改变的数据
        if (item.id === action.payload.id) {
          //如果数据的长度为1，那就人为添加1，起到动态的效果
          const max = state.showgonlist[index].point.length;
          //   console.log(max)
          if (max > 1) {
            state.showgonlist[index].point[max - 1] = action.payload.lastpoint;
          } else {
            state.showgonlist[index].point[1] = [];
          }
        }
      });

      // console.log(action.payload.mappointlist)
    },
    //删除指定的多边形
    delpolygonaction(state, action) {
      state.showgonlist.forEach((item, index) => {
        if (item.id === action.payload.id) {
          state.showgonlist.splice(index, 1);
        }
      });
    },
    //多边形二次编辑
    polygonedit(state, action) {
      // console.log(action.payload)
      state.showgonlist.forEach((item, index) => {
        //找到id相同并且已经画完线的数据
        if (
          item.id === action.payload.id &&
          state.showgonlist[index].isover === true
        ) {
          state.showgonlist[index].point = action.payload.point;
        }
      });
    },
    savepolygonaction(state, action) {
      // 保存所有的数据
      state.showgonlist = action.payload.polygon.showgonlist;
    },
    geohashpolygonaction(state, action) {
      // 保存所有的数据
    //   console.log(action)
      state.showgonlist.push(action.payload);
    },
  },
});

export const {
  dellasttwo,
  addoneaction,
  updataminaction,
  delpolygonaction,
  polygonedit,
  savepolygonaction,
  geohashpolygonaction,
} = polygonSlice.actions;
export default polygonSlice.reducer;
