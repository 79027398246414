import React, { memo, useRef, useState } from "react";
import { Input, Select, Button,message  } from "antd";
import { Mainwrap } from "./style";
import gcoord from "gcoord";
import {copyPageUrl} from "../../../utils/copydata"

// const {
//   convertBdMC2LL,
//   convertBdLL2MC,
//   transformWGS2GCJ,
//   transformGCJ2WGS,
//   baiduTomars,
//   marsTobaidu,
// } = maptools;

const { TextArea } = Input;
const selectoption = [
  {
    value: "百度-->原始",
    label: "百度-->原始",
  },
  {
    value: "百度-->火星",
    label: "百度-->火星",
  },
  {
    value: "火星-->百度",
    label: "火星-->百度",
  },
  {
    value: "火星-->原始",
    label: "火星-->原始",
  },
  {
    value: "原始-->火星",
    label: "原始-->火星",
  },
  {
    value: "原始-->百度",
    label: "原始-->百度",
  },
];

const Gpscon = memo(() => {
  //临时存储select的值
  const [messageApi, contextHolder] = message.useMessage();
  const [selectvalue, setselectvalue] = useState("火星-->百度");
  const [afterinput,setafterinput]=useState("")

  const selectref = useRef();
  const beforegpsref = useRef();
  const afteregpsref = useRef();

  const changegps = () => {
    // console.log(selectvalue);
    // console.log(beforegpsref.current.resizableTextArea.textArea.value);
    // console.log(afteregpsref.current.resizableTextArea.textArea.value);
    //这里拿到input中的值
    const inputgps = beforegpsref.current.resizableTextArea.textArea.value;
    let reg = new RegExp("，", 'g')
    const arr = inputgps.trim().replace(reg, ",").split("\n"); //使用trim去除首位所有的空格和回车
    let objarr = [];
    beforegpsref.current.resizableTextArea.textArea.value=inputgps.trim().replace(reg, ",")
    // console.log(objarr)

    const selarr = selectvalue.split("-->");
    let seleval = [];
    selarr.forEach((item) => {
      switch (item) {
        case "百度":
          seleval.push(gcoord.BD09);
          break;
        case "原始":
          seleval.push(gcoord.WGS84);
          break;
        case "火星":
          seleval.push(gcoord.GCJ02);
          break;
        default:
          break;
      }
    });
    arr.forEach((item)=>{
        objarr.push(
            gcoord.transform(item.split(","),seleval[0],seleval[1])
        )
    })
    // console.log(objarr)
    let resarr=[]
    objarr.forEach(item => {
        resarr.push(item.join(","))
    });
    let restr = resarr.join("\n")
    setafterinput(restr)
    //复制转化后的值
    copyPageUrl(restr,messageApi,"转化后经纬度已经复制")
    // console.log(restr)
  };

  //动态设置选中数据的值
  const savevalue = (value) => {
    setselectvalue(value);
  };

  return (
    <Mainwrap>
        {contextHolder}
      <div>
        <Select
          className="select"
          options={selectoption}
          defaultValue={"火星-->百度"}
          ref={selectref}
          onChange={savevalue}
        ></Select>
        <Button type="primary" onClick={changegps}>
          转换
        </Button>
      </div>
      <div className="divclass">
        <TextArea
          rows={10}
          placeholder="此处输入需要转换的经纬度
1.输入经纬度以逗号分隔，示例120.874651，28.87312，多行以回车分隔
2.首尾空格以及换行会在匹配时自动忽略
3.中文逗号也支持转换"
          style={{ width: 300 }}
          className="beforegps"
          ref={beforegpsref}
        />
        <TextArea
          rows={10}
          placeholder="此处为转换后的经纬度"
          style={{ width: 300 }}
          className="aftergps"
          ref={afteregpsref}
          readOnly={"readOnly"}
          value={afterinput}
        />
      </div>
    </Mainwrap>
  );
});

export default Gpscon;
