import React, { memo, useRef, useState, useEffect } from "react";
import { useBoolean, useToggle } from "ahooks";

import { Mainwrap } from "./style";
import { Button, Layout, Modal, Menu, Input } from "antd";
import http from "../../../../services/request";

import moment from "moment/dist/moment";
import "moment/dist/locale/zh-cn";
import "moment-lunar";




const Headerfile = memo(() => {


    const [projectname,setprojectname]=useState("ONE-MAP")
  //geohash转化经纬度

  const { Header } = Layout;
  moment.locale("zh-cn");
  const [currentTime, setCurrentTime] = useState(moment().format("lll"));
  useEffect(() => {
    const interval = setInterval(() => {
      const gettime = async () => {
        const nowtime = await http({
          url: "bjtime",
        }).then((response) => {
          const date = moment(parseInt(response.data.t));

          const formattedDate = date.format("lll");
          // console.log(formattedDate)
          setCurrentTime(formattedDate);
        });
      };
      http.post("/project/getname").then((response)=>{
        setprojectname(response.data[0].title)
    })
      gettime();
    }, 10000);

    return () => clearInterval(interval);
  }, []);



  return (
    <Mainwrap>
      <Layout>
        <Header className="header">
          {/* <div className="logo" >
          </div> */}
          <div className="title">{projectname}</div>

          <div className="div1">
            
          

            <div className="time">{currentTime}</div>
          </div>
        </Header>
      </Layout>


    </Mainwrap>
  );
});

export default Headerfile;
