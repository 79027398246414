import React, { memo, useRef, useState } from "react";
import { Button, Input,message } from "antd";
import { Mainwrap } from "./style";
const Imei = memo(() => {
  const inputref = useRef();
  const outputref = useRef();
  const [outputstate, setoutputstate] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const imeiconversion = (e) => {
    const inputvalue = inputref.current.input.value; //输入框得值
    const outputvalue = isImei(inputvalue);
    // console.log(outputvalue)
    setoutputstate(outputvalue);
    success()
  };

  const isImei = (imeiString) => {
    //imei升位
    let i = 0;
    let vl_Sum1 = 0,
      vl_Sum2 = 0,
      vl_Total = 0;
    let vl_Temp = 0;

    for (i = 0; i < 14; i++) {
      /*(1)将奇数位数字相加(从1开始计数)*/
      if (i % 2 == 0) {
        vl_Sum1 = vl_Sum1 + parseInt(imeiString[i]);
      } else {
        /*(2)将偶数位数字分别乘以2,分别计算个位数和十位数之和(从1开始计数)*/
        vl_Temp = parseInt(imeiString[i]) * 2;
        if (vl_Temp < 10) {
          vl_Sum2 = vl_Sum2 + vl_Temp;
        } else {
          vl_Sum2 = vl_Sum2 + 1 + vl_Temp - 10;
        }
      }
    }
    /*(1)+(2)*/
    vl_Total = vl_Sum1 + vl_Sum2;
    // console.log(vl_Sum1,vl_Sum2)
    /*如果得出的数个位是0则校验位为0,否则为10减去个位数 */
    if (vl_Total % 10 == 0) {
      // console.log(1)
      
        return imeiString + "" + 0;
      
    } else {
      // console.log(2)
      return imeiString + (10 - (vl_Total % 10)).toString();
    }
    return false;
  };


 
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'IMEI升位成功',
    });
  };
  return (
    <Mainwrap>
        {contextHolder}
      <div className="workspace">
        <Input
          className="imeiinput"
          placeholder="输入14位IMEI"
          ref={inputref}
          maxLength={14}
          showCount
        />
        <Button type="primary" className="changebtn" onClick={imeiconversion}>
          补全
        </Button>
        <Input
          className="imeioutput"
          placeholder="输出15位IMEI"
          ref={outputref}
          maxLength={15}
          value={outputstate}
          showCount
        />
      </div>
    </Mainwrap>
  );
});

export default Imei;
