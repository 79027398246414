import React, { memo, useRef, useState } from "react";
import { Input, Select, Button, message } from "antd";
import { Mainwrap } from "./style";
import http from "../../../services/request";
import {
  addonepointaction,
  updataonpoint,
  changeshowplist_all,
} from "../../../store/mappointSlice";
import { v4 as uuidv4 } from "uuid";
import { copyPageUrl } from "../../../utils/copydata";
import { useDispatch } from "react-redux";

// const {
//   convertBdMC2LL,
//   convertBdLL2MC,
//   transformWGS2GCJ,
//   transformGCJ2WGS,
//   baiduTomars,
//   marsTobaidu,
// } = maptools;

const Wifi = memo((props) => {
  const { setlbsModalOpen } = props;
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  //临时存储选中的select的值
  const wifiref = useRef();
  const bzref = useRef();

  const searchlbs = async () => {
    //取得输入框中的值
    let wifi = wifiref.current.input.value;
    let newwifi = [];
    [...wifi].forEach((item, index) => {
      newwifi.push(item);
      index % 2 == 1 && [...wifi].length != index+1 && newwifi.push(":");
    });
    // console.log(newwifi.join(""));
    wifi = newwifi.join("");
    const bz = bzref.current.input.value;
    const lbsdata = await http({
      url: "lbswifi",
      params: {
        mac: wifi,
        coord: "bd09",
        output: "json",
      },
    }).then(
      (response) => {
        if (response.errcode === 0) {
          const id = uuidv4();
          const bzvalue = wifi;
             console.log(response.address, response.lat, response.lon);
          const onepoint = {
            latlng: [response.lon, response.lat],
            id: id,
          };
          //添加基站点位数据到地图中
          dispatch(addonepointaction({ onepoint: onepoint }));
          if (bz == "") {
            dispatch(
              updataonpoint({
                remarks: bzvalue,
                id: id,
                type:"WIFI",
                bz:"MAC："+wifi
              })
            );
            setlbsModalOpen(false);
            dispatch(changeshowplist_all());
          } else {
            dispatch(
              updataonpoint({
                remarks: bz,
                id: id,
                type:"wifi",
                bz:wifi
              })
            );
            setlbsModalOpen(false);
            dispatch(changeshowplist_all());
          }
        } else {
          messageApi.open({
            type: "success",
            content: "未查询到WIFI数据",
            duration: 3,
          });
        }
      },
      (error) => {
        console.log("错误", error.message);
        messageApi.open({
          type: "success",
          content: error.message,
          duration: 3,
        });
      }
    );

    //复制转化后的值
    // copyPageUrl(restr,messageApi,"转化后经纬度已经复制")
  };

  return (
    <Mainwrap>
      {contextHolder}
      <div className="divclass">
        <Input
          className="wifi"
          placeholder="输入WIFI 12位 MAC"
          maxLength={12}
          ref={wifiref}
        />
        <Input className="bz" placeholder="备注" ref={bzref} />
        <Button type="primary" onClick={searchlbs}>
          定位
        </Button>
      </div>
    </Mainwrap>
  );
});

export default Wifi;
