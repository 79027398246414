import React, { memo } from "react";
import { Input, Card } from "antd";
import { Mainwrap } from "./style";
const Search = memo((props) => {
  const BMap = window.BMapGL;

  const { map } = props;
  // ---------------

  let ac = new BMap.Autocomplete({
    //建立一个自动完成的对象
    input: "suggestId",
    location: map,
  });
  ac.input = "永嘉";

  ac.addEventListener("onhighlight", function (e) {
    //鼠标放在下拉列表上的事件
    let str = "";
    let _value = e.fromitem.value;
    let value = "";
    if (e.fromitem.index > -1) {
      value =
        _value.province +
        _value.city +
        _value.district +
        _value.street +
        _value.business;
    }
    str =
      "FromItem<br />index = " + e.fromitem.index + "<br />value = " + value;

    value = "";
    if (e.toitem.index > -1) {
      _value = e.toitem.value;
      value =
        _value.province +
        _value.city +
        _value.district +
        _value.street +
        _value.business;
    }
    str +=
      "<br />ToItem<br />index = " + e.toitem.index + "<br />value = " + value;
    // G("searchResultPanel").innerHTML = str;
  });

  let myValue;
  ac.addEventListener("onconfirm", function (e) {
    //鼠标点击下拉列表后的事件
    let _value = e.item.value;
    myValue =
      _value.province +
      _value.city +
      _value.district +
      _value.street +
      _value.business;
    // G("searchResultPanel").innerHTML ="onconfirm<br />index = " + e.item.index + "<br />myValue = " + myValue;

    setPlace();
  });

  function setPlace() {
    //清除地图上所有覆盖物

    function myFun() {
      let pp = local.getResults().getPoi(0).point; //获取第一个智能搜索的结果

      map.centerAndZoom(pp, 14);
      const pmaker = new BMap.Marker(pp);
      pmaker.id = "temporary";//添加唯一标识符，作为后期删除的依据
      map.addOverlay(pmaker);

      //添加标注
    }
    function point(poi, html) {
      console.log(poi, html);
    }

    let local = new BMap.LocalSearch(map, {
      //智能搜索
      onSearchComplete: myFun,
      onInfoHtmlSet: point,
      renderOptions: { map: map },
    });
    local.setPageCapacity(1);
    local.search(myValue);


    //弹一个保存框，选择是否保存这个地点点位到地图上，并且可以输入备注
    
    //延迟删除前期打好的点
    // setTimeout(() => {
    //   local.clearResults();
    //   const allOverlays = map.getOverlays();
    //   //console.log(allOverlays);
    //   for (let i = 0; i < allOverlays.length; i++) {
    //     if (allOverlays[i].id && allOverlays[i].id === "temporary") {
    //       map.removeOverlay(allOverlays[i]);
    //     }
    //   }
    // }, 2000);
  }

  // --------------------

  return (
    <Mainwrap>
      <Input id="suggestId" className="input1" placeholder="搜索位置"></Input>
      <div id="r-result"></div>
      <div id="searchResultPanel"> </div>
    </Mainwrap>
  );
});

export default Search;
