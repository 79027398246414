import { configureStore } from "@reduxjs/toolkit";

import mappointSlice from "./mappointSlice";
import lineSlice from "./lineSlice";
import circleSlice from "./circleSlice";
import polygonSlice from "./polygonSlice";
import userSlice from "./userSlice";


export default configureStore({
  //暴漏创建的store
  reducer: {
    //每个store只允许有一个reducer，不过这个reducer可以是许多个小块拼接而成
    mappoint: mappointSlice,
    line:lineSlice,
    circle:circleSlice,
    polygon:polygonSlice,
    user:userSlice
    // movie: movieSlice,
  },
});
