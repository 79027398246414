import React, { memo } from 'react'
import Mainmap from '../mainmap'
import Headerfile from '../headerfile'
import Footerba from '../btnapp/footer'

const Userface = memo(() => {
  return (
    <div>
        <Headerfile/>
        <Mainmap/>
        <Footerba/>
    </div>
  )
})

export default Userface