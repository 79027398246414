import styled from "styled-components";

export const Mainwrap = styled.div`
    .searchadd{
        position: absolute;
    }
    .input{
        /* display: none; */
        width: 0;
        height: 0;
        position: absolute;
    }
`;