import React, { memo } from 'react'
import {Button, List} from "antd"
import {Mainwrap} from './style'
import { useSelector,useDispatch } from 'react-redux';
import {changeshowplist} from "../../../store/mappointSlice"


const Listdata = memo((props) => {
    const BMap = window.BMapGL;
    const dispatch=useDispatch()
    const { pointlist,showplist } = useSelector((store) => store.mappoint);
    // console.log(pointlist)
    const listitemclick=(e,item)=>{
        //遍历所有的点位
        const allOverlays = props.map.getOverlays();
      for (let i = 0; i < allOverlays.length; i++) {
         
        allOverlays[i].setZIndex(0)
        if(allOverlays[i].id&&allOverlays[i].id==item.id){
            allOverlays[i].setZIndex(1)
// console.log(allOverlays[i])
            props.map.centerAndZoom(new BMap.Point(allOverlays[i].latLng.lng,allOverlays[i].latLng.lat) );
        }
        
      }
    //   console.log(allOverlays)
      
    //props.addonep(item.latlng,BMap,props.map,copyPageUrl)
}

//关闭列表
const changelist=()=>{
    dispatch(changeshowplist())
}

  return (
    <Mainwrap>
        
        <Button className='btn' type='primary' onClick={changelist}>{showplist?"隐藏列表":"展开列表"}</Button>
        {showplist?<List
      header={<div>当前地图点位</div>}
      dataSource={pointlist}
      bordered
      className='list'
      renderItem={(item,index) => (
        <List.Item onClick={e=>listitemclick(e,item)}>
            {item.latlng[2]==undefined?index+1+"、"+(item.latlng[0]+"").slice(0,8)+"，"+(item.latlng[1]+"").slice(0,8):index+1+"、"+item.latlng[2]}
            
        </List.Item>
        
      )}
      pagination={{
        simple:true,
        total:pointlist.length,
        align:"center"
        
    }}
    />:null}
    </Mainwrap>
  )
})

export default Listdata;