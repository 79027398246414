import styled from "styled-components";

export const Mainwrap = styled.div`
  display: flex;
  flex-direction: column;
  .select {
    flex: 1;
    width: 70px;
  }
  .divclass{
    flex: 50;
    display: flex;
  }
  .laclng{
    width: 120px;
  }
  .cid{
    width: 170px;
  }



`;
