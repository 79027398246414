import styled from "styled-components";

export const Mainwrap = styled.div`
  .workspace{
    display: flex;
    
    .imeiinput{
        width: 220px;
    }
    .imeioutput{
        width: 220px;
    }
    .changebtn{
        margin: 0px 5px 0px 5px;
    }
  }
`;
