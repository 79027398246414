import React, { memo, useRef, useState } from "react";
import { Button, Input, message, QRCode } from "antd";
import { Mainwrap } from "./style";
const Qrcode = memo(() => {
  const { TextArea } = Input;

  const inputref = useRef();
  const [inputstate, setinputstate] = useState("-");

  const inputvaluechange = (e) => {
    const inputvalue = inputref.current.resizableTextArea.textArea.value; //输入框得值
    // console.log(inputvalue);
    setinputstate(inputvalue);
  };


  return (
    <Mainwrap>
      <div className="workspace">
        <TextArea
          className="imeiinput"
          placeholder="输入文字自动转化二维码"
          ref={inputref}
          onChange={inputvaluechange}
          style={{ width: "300px" }}
        />

        <QRCode value={inputstate === "" ? "啥都没有" : inputstate} />
      </div>
    </Mainwrap>
  );
});

export default Qrcode;
