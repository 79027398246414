import { createSlice } from "@reduxjs/toolkit";
// 等待更改
export const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    user: {},
    islogin:false
  },
  reducers: {

    userloginsuccess(state,action){
      const {openid,name}=action.payload
      state.islogin=true
      state.user={
        openid,
        name
      }

    },

    loginout(state,action){
      state.islogin=false
      localStorage.removeItem('loginid')
    },
    //点击之后添加一个折线点位
    addoneaction(state, action) {
      let i = 0;
      state.showgonlist.forEach((item, index) => {
        if (item.id === action.payload.id) {
          state.showgonlist[index].point.push(action.payload.onepoint);
          i = i + 1;
        }
      });
      if (i === 0) {
        state.showgonlist.push({
          id: action.payload.id,
          point: [action.payload.onepoint],
        });
      }
    },
  },
});

export const {
  loginout,
  userloginsuccess,
  addoneaction,
} = userSlice.actions;
export default userSlice.reducer;
