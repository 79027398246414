import { Button, Input, Modal } from "antd";
import React, { memo, useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Userface from "../../userface";
import { Mainwrap } from "./style";
import http from "../../../services/request";
import { useBoolean } from "ahooks";
import { savecircleaction } from "../../../store/circleSlice";
import { showprojectpoint } from "../../../store/mappointSlice";
import { savepolygonaction } from "../../../store/polygonSlice";
import { savelineaction } from "../../../store/lineSlice";
const Projectconfig = memo(() => {
  const [state, { toggle, setTrue, setFalse }] = useBoolean(false);
  const dispatch = useDispatch();
  const inputref = useRef();
  const inputeditname = useRef();

  const allstore = useSelector((store) => store);

  //获取案件名称
  const [casenamestate, setcasenamestate] = useState();
  const getname = () => {
    const casename = http.post("project/getname").then((response) => {
        console.log(response)
      setcasenamestate(response.data[0].title);
    });
  };
  getname();
  // console.log(casename)
    // const getprojectinter = setTimeout(() => {
        useEffect(()=>{

        
        http
        .post("project/getall", {
          setzero: false,
        })
        .then((response) => {
          //   console.log(response);
          //   console.log(response.data.draw)
          if (response.msg === "success") {
            // console.log(response)
            const draw = response.data.draw[0];
    
            // console.log(circle,line,polygon)
            const point = response.data.point;
            const realpoint = point.map((obj) => {
              obj.latlng = JSON.parse(obj.latlng);
              return obj;
            });
            if (draw && "circle" in draw) {
              const circle = JSON.parse(draw.circle);
              dispatch(savecircleaction({ circle: circle }));
            }
            if (draw && "line" in draw) {
              const line = JSON.parse(draw.line);
              dispatch(savelineaction({ line: line }));
            }
            if (draw && "polygon" in draw) {
              const polygon = JSON.parse(draw.polygon);
              dispatch(savepolygonaction({ polygon: polygon }));
            }
    
            dispatch(showprojectpoint({ point: realpoint }));
            // clearInterval(getprojectinter);
          }
          //   console.log(point);
        });
    },[])
    // }, 5000);




    
    

  //保存数据
  const tbbtn = async () => {
    const save = await http.post("project/save", {
      store: allstore,
    });
  };
  //编辑modal
  const editname = () => {
    setTrue();
  };
  const editcancel = () => {
    setFalse();
  };
  //修改名称
  const changename = async () => {
    const casename = inputeditname.current.input.value;
    const save = await http.post("project/casename", {
      casename: casename,
    });

    editcancel();
    getname();
  };

  return (
    <Mainwrap>
      <Button type="primary" className="tb" onClick={tbbtn}>
        同步
      </Button>
      {/* <div classname='casename'>专案名称</div> */}
      <p className="casename">专案名称:{casenamestate}</p>
      <Button type="primary" className="edit" onClick={editname}>
        修改名称
      </Button>
      {/* <Input className="casename" placeholder='输入专案名称' ref={inputref}/> */}
      <div className="biaoti">专案显示配置界面</div>

      <Userface />
      <Modal
        title="修改专案名称"
        open={state}
        onCancel={editcancel}
        destroyOnClose={true}
        footer={[]}
      >
        <Input ref={inputeditname} />
        <Button type="primary" onClick={changename}>
          修改
        </Button>
      </Modal>
    </Mainwrap>
  );
});

export default Projectconfig;
