import React, { memo, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button, Input, List, message, Popconfirm, Card } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Mainwrap } from "./style";
import dayjs from "dayjs";
import { savecircleaction } from "../../../store/circleSlice";
import { savelineaction } from "../../../store/lineSlice";
import {
  savepointaction,
  changeshowplist_all,
  originalpointsave
} from "../../../store/mappointSlice";
import { savepolygonaction } from "../../../store/polygonSlice";
import { xcxapi } from "../../../services/config";
import axios from "axios";
import { copyPageUrl } from "../../../utils/copydata";

const CloudSave = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [listdata, setlistdata] = useState([]);
  const { map } = props;
  const success = () => {
    messageApi.open({
      type: "success",
      content: "数据已添加到地图",
    });
  };
  const dispatch = useDispatch();

  const allstore = useSelector((store) => store);

  const { user, circle, line, mappoint, polygon } = allstore;

  useEffect(() => {
    axios
      .post(`${xcxapi}/map/defaultcloudlist`, {
        user: user.user.name,
        openid: user.user.openid,
      })
      .then((resu) => {
        setlistdata(resu.data.data);
      });
  }, []);

  //   console.log(allstore)
  const inputref = useRef();
  //拿到所有的数据
  //添加数据
  const savealldata = () => {
    let inputvalue = inputref.current.input.value; //拿到input中保存的数据
    if (inputvalue === "") {
      inputvalue = "未命名";
    }

    axios
      .post(`${xcxapi}/map/savemapdata`, {
        user: user.user.name,
        openid: user.user.openid,
        dataname: inputvalue,
        mapdata: {
          circle,
          line,
          mappoint,
          polygon,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          message.success("保存成功");
          axios
            .post(`${xcxapi}/map/defaultcloudlist`, {
              user: user.user.name,
              openid: user.user.openid,
            })
            .then((resu) => {
              setlistdata(resu.data.data);
            });
        } else {
          message.error("保存失败");
        }
      });
  };

  const share = (item) => {
    const { shareid } = item;
    copyPageUrl(shareid, messageApi, `分享链接:${shareid},已经复制至剪贴板`);
  };

  //显示所有的数据
  const showlistdata = (itemstr) => {
    // const item = JSON.parse(itemstr.data);
    let item;
    axios
      .post(`${xcxapi}/map/getonedatabyid`, { _id: itemstr._id })
      .then((res) => {
        console.log("111");
        item = JSON.parse(res.data.data.data);

        const pointarr=item.mappoint.pointmany
        let st=""
        for(const itemx of pointarr){
          st=st+itemx.latlng.join(',')+'\n'
        }



        dispatch(savecircleaction({ circle: item.circle }));
        dispatch(savelineaction({ line: item.line }));
        dispatch(savepointaction({ point: item.mappoint }));
        dispatch(savepolygonaction({ polygon: item.polygon }));
        dispatch(changeshowplist_all());
        dispatch(originalpointsave({ point: st }));
      });
    //保存数据

    // console.log(item.   )
    success();
  };

  //删除当前的数据
  const dellistdata = (item) => {
    const { _id, openid, user } = item;
    console.log(openid)
    axios
      .post(`${xcxapi}/map/delonedata`, {
        _id,
        openid,
        user,
      })
      .then((res) => {
        console.log(res)
        if (res.data.type === "success") {
          message.success("删除成功");
          axios
            .post(`${xcxapi}/map/defaultcloudlist`, {
              openid: openid,
              user: user,
            })
            .then((resu) => {
              setlistdata(resu.data.data);
            });
        } else {
          message.error("删除失败");
        }
      });
  };

  //更新操作
  const updata = (item) => {
    console.log("需要更新的数据：", item.shareid);
    axios
      .post(`${xcxapi}/map/updataonedata`, {
        user: user.user.name,
        openid: user.user.openid,
        shareid: item.shareid,
        mapdata: {
          circle,
          line,
          mappoint,
          polygon,
        },
      })
      .then((res) => {
        if (res.data.type === "success") {
          message.success("更新成功");
          axios
            .post(`${xcxapi}/map/defaultcloudlist`, {
              user: user.user.name,
              openid: user.user.openid,
            })
            .then((resu) => {
              setlistdata(resu.data.data);
            });
        } else {
          message.error("更新失败");
        }
      });
  };
  return (
    <Mainwrap>
      {contextHolder}
      <div className="top">
        <Input placeholder="输入名称" className="bz" ref={inputref} />
        <Button type="primary" onClick={savealldata} className="savebtn">
          保存
        </Button>
      </div>
      <List
        className="list"
        itemLayout="horizontal"
        dataSource={listdata}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              <a key="list-loadmore-edit" onClick={() => share(item, index)}>
                分享
              </a>,
              <a
                key="list-loadmore-edit"
                onClick={() => showlistdata(item, index)}
              >
                显示
              </a>,
              <Popconfirm
                title="确定将当前的图上数据覆盖至本条记录？此操作不可逆！！！"
                onConfirm={() => updata(item, index)}
                // onCancel={cancel}
                okText="更新"
                cancelText="取消"
              >
                <a key="list-loadmore-more">更新</a>
              </Popconfirm>,
              <Popconfirm
                title="确定删除吗？"
                // onCancel={cancel}
                okText="删除"
                cancelText="取消"
                onConfirm={() => dellistdata(item)}
              >
                <a
                  key="list-loadmore-more"
                  //   onClick={() => dellistdata(item, index)}
                >
                  删除
                </a>
              </Popconfirm>,
            ]}
          >
            <List.Item.Meta
              title={item.dataname}
              description={`更新于：${dayjs(item.createdAt).format(
                "YYYY-MM-DD HH:mm:ss"
              )}`}
              //   description={`分享码：${item.shareid}}`}
            />
          </List.Item>
        )}
        pagination={{
          total: listdata.length,
          align: "center",
          pageSize: 8,
        }}
      />
      <Card>
        <h2 className="h1">使用说明</h2>
        <p>
          1.点击"分享"之后就会自动复制分享码至剪贴板，然后在微信小程序端输入分享码就可以共享数据。
        </p>
        <p>
          2.为了数据安全，本平台禁止在分享的数据中以及名称中涉及任何的实名信息（包括但不限于姓名，手机号，身份证号）。
        </p>
        <p>
          3.在云收藏中的数据会上传至服务器，由于平台搭建在互联网，无法对数据保密性进行保证。
        </p>
      </Card>
    </Mainwrap>
  );
};

export default CloudSave;
